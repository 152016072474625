
import HeaderSimple from "./HeaderSimple";
import React, { useEffect, useState } from 'react';
import FooterBar from "./FooterBar";
import VerificationPopup from "../ConfirmationPages/VerificationPopup";
import backImgTel from "../../images/Union_tel2.png";
import backMeet from "../../images/Background_Meet.jpg";
import { useLocation, useParams } from "react-router";
import JitsiMeetComponent from "./JitsiMeetComponent";
import JitsiMeet from "../JItsiMeet";
import {date} from "yup";
const PageMeet = () => {
    const jetsiUrl = 'https://8x8.vc/vpaas-magic-cookie-8796d75b3e99425fae680de9e2afaa26/677?jwt=eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtODc5NmQ3NWIzZTk5NDI1ZmFlNjgwZGU5ZTJhZmFhMjYvNzE5Njg5IiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsInN1YiI6InZwYWFzLW1hZ2ljLWNvb2tpZS04Nzk2ZDc1YjNlOTk0MjVmYWU2ODBkZTllMmFmYWEyNiIsIm5iZiI6MTcyMDc4MDE5NSwiaXNzIjoiY2hhdCIsImNvbnRleHQiOnsiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6dHJ1ZSwib3V0Ym91bmQtY2FsbCI6dHJ1ZSwic2lwLW91dGJvdW5kLWNhbGwiOnRydWUsInRyYW5zY3JpcHRpb24iOnRydWUsInJlY29yZGluZyI6ZmFsc2V9LCJ1c2VyIjp7ImhpZGRlbi1mcm9tLXJlY29yZGVyIjpmYWxzZSwibW9kZXJhdG9yIjpmYWxzZX19LCJleHAiOjE3MjA4MTA4MDAsImlhdCI6MTcyMDc4MDIwMCwicm9vbSI6IioifQ.G23G3Z4wrWn8Km14H9yC156GToHQEnaSO0PC0kBTVY_TNNxJH9lleOch-GqtE5380jzz6yQPZv3_ucIFe02Dh50es2jzJXF6NU4ISOtbZL6VYJKxICJ6q3Evigf6L36OawbfG_i2h0-u_jKlZZ_eF_y5n_4DlONnvuY5Y4fQJo2N_wJBd_1kShGAcVdRyExkJI-uWEMLeJEjdz8nToPCbheEj8Igy_c9kD-D-canGzskVvCxR-Y0IdOv2VvJ7NviH9PSJuUef1Q2U8tN1HNYNDortETadNjYXLE6M7QmDCmTOQfMsZFLnV_cXK3CviXJ_uv_vhCUPbj0fJkMSlweOg#config.prejoinPageEnabled=false&config.startWithAudioMuted=false&config.startWithVideoMuted=false&config.startWithScreenSharing=true';
    const [isVerified, setIsVerified] = useState(false);
    const [openPopup, setOpenPopup] = useState(true);
    const [dateDebut, setDateDebut] = useState();
    const [dateFin, setDateFin] = useState();
    // Function to update parent's state
    const updateInfoVisioCall = (debut, fin) => {
        setDateDebut(debut)
        setDateFin(fin)
        console.log(date,fin)
    };
    const { id } = useParams();
    const location = useLocation();
    // Function to get query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const jwt = getQueryParams(location.search).get('jwt');
    useEffect(() => {
        // Afficher la pop-up au chargement de la page
        setOpenPopup(true);
    }, []);
    const handleVerified = () => {
        setIsVerified(true);
        setOpenPopup(false);
    };
    return (
        <>
            <style jsx>{`
                @media (max-width: 768px) {
                    .dialog {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    .dialog {
                        background-image: url(${backMeet});
                        background-size: cover;
                    }
                }
            `}</style>
            <div className={"dialog"}>
                {<VerificationPopup
                    open={openPopup}
                    updateInfoVisioCall={updateInfoVisioCall}
                    roomName={id}
                    onClose={() => setOpenPopup(true)}
                    onVerified={handleVerified}
                />}
                {isVerified && (
                    <div>
                        <div className="min-h-screen flex flex-col justify-center items-center">
                            <div className="flex-col w-3/4  flex justify-center items-center">
                                <h2 className="text-3xl font-bold mb-5 shadow-lg text-white">Bienvenue à notre réunion</h2>
                                <div
                                    className=" w-full rounded-2xl shadow-[0_2px_8px_-1px_rgba(0,0,0,0.3)] ring-4 ring-white md:w-3/4 h-[500px] mx-auto">
                                    <JitsiMeet dateDebut={dateDebut} dateFin={dateFin} roomName={id} jwt={jwt} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/*{isVerified && (
                    <div>
                        <div className="min-h-screen flex flex-col justify-center items-center">
                            <div className="flex-col w-3/4  flex justify-center items-center">
                                <h2 className="text-3xl font-bold mb-5 shadow-lg text-white">Bienvenue à notre réunion</h2>
                                <div
                                    className=" w-full rounded-2xl shadow-[0_2px_8px_-1px_rgba(0,0,0,0.3)] ring-4 ring-white md:w-3/4 h-[500px] mx-auto">
                                    <iframe
                                        src={`${jetsiUrl}`}
                                        allow="microphone; camera; display-capture;"
                                        className="w-full rounded-2xl h-full"
                                        allowFullScreen
                                        frameBorder="0"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </>
    );
};
export default PageMeet;