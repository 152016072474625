import React, {useEffect, useState} from 'react';
import backImg from '../../images/Union_v2.png';
import backImgTel from '../../images/Union_tel2.png';
import Navbar from "./Navbar";
export default function HeaderSimple({mainTitle, subTitle}) {


    return (
        <header
            className="relative pb-5  h-80 text-white w-screen md:bg-cover md:bg-bottom flex flex-col"
        >
            <style jsx>{`
                @media (max-width: 768px) {
                    header {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    header {
                        background-image: url(${backImg});
                    }
                }
            `}</style>
            <Navbar/>
            <div
                className="w-full h-full items-start p-6 justify-center md:w-4/5 md:mx-auto font-montserrat flex-grow flex flex-col">
                <div className="text-2xl text-center m-auto items-center xl:text-4xl">
                    <h1 className="font-montserrat text-3xl md:text-4xl font-extrabold">{mainTitle}</h1>
                    <h1 className="font-montserrat w-full  mx-auto mt-5 text-lg md:text-2xl font-medium">{subTitle}</h1>

                </div>
            </div>

        </header>


    );
}
