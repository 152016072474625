import React, {useCallback, useEffect, useRef, useState} from 'react';
import FooterBar from "./FooterBar";
import HeaderSimple from "./HeaderSimple";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import apiClient from "../../Services/apiService";
import {getDomaineService} from "../../Services/DomaineService";
import {debounce} from "lodash";
import NosExperts_V2 from "./NosExperts_V2";
import TopExperts from "./TopExperts";

export default function PageNosExperts() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState('');

    const handleSearch = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`experts/nomOrPrenom?keyword=${encodeURIComponent(searchTerm)}`);
            const data = await response.json();

            if (data.length > 0) {
                // Récupérer tous les IDs des experts ayant le même nom
                const expertIds = data.map(expert => expert.idExpert);
                // Construire une chaîne d'IDs séparés par une virgule
                const expertIdsString = expertIds.join(',');
                // Envoyer tous les IDs à la page suivante dans une seule URL
                navigate(`/expertSelected/${encodeURIComponent(expertIdsString)}`);
            } else {
                console.error('Aucun expert trouvé pour ce nom');
                // Afficher un message à l'utilisateur si aucun expert n'est trouvé
                toast.warning(`Aucun expert trouvé pour le nom : ${searchTerm}`);
            }
        } catch (error) {
            console.error('Erreur lors de la recherche des experts : ', error);
            // Gérer l'erreur, par exemple afficher un message d'erreur à l'utilisateur
            toast.error(`Aucun expert trouvé pour le nom : ${searchTerm}`);
        }
    };

    const fetchSuggestions = async (value) => {
        try {
            const response = await apiClient.get('/experts/nomOrPrenom', { params: { keyword: value } });
            setSuggestions(response.data);
            if (response.data.length === 0) {
                setError('Aucun expert trouvé');
            }
        } catch (error) {
            console.error('Erreur lors de la recherche de l\'expert:', error);
            setError('Erreur lors de la recherche de l\'expert');
        }
    };

    // Utilisez useCallback pour mémoriser la fonction debounce
    const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 400), []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setError('');

        if (value.length >= 2) {
            debouncedFetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (expertId) => {
        navigate(`/expertSelected/${expertId}`);
    };

    return (
        <div className="">
            <HeaderSimple
                mainTitle="Votre équipe d'experts dédiée"
                subTitle="Rencontrez nos experts"
            ></HeaderSimple>

            <form className=" w-full flex p-4 flex-col items-center" onSubmit={handleSearch} autoComplete="off">
                <div>
                    <h1 htmlFor="default-search" className="mb-5 font-poppins text-md font-semibold text-dark">Trouvez votre expert
                                                                                                   ici :</h1>
                </div>

                <div className="relative w-full md:w-[450px]">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="search" id="default-search"
                           className="block w-full p-4 ps-10 focus:border-blue-500 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  "
                           placeholder="Recherche par nom de l'expert..." required
                           value={searchTerm} onChange={handleChange}/>
                    <button type="submit"
                            className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-full text-sm px-4 py-2">
                        Search
                    </button>
                    {suggestions.length > 0 && (
                        <div
                            id="dropdownDefaultCheckbox"
                            className="absolute mt-1 w-full md:w-96 bg-white border border-gray-300 rounded-lg shadow z-10"
                        >
                            <ul className="text-sm text-gray-700">
                                {suggestions.map(suggestion => (
                                    <li key={suggestion.idExpert}
                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => handleSuggestionClick(suggestion.idExpert)}>
                                        {suggestion.nomExpert} {suggestion.prenomExpert}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

            </form>
            <TopExperts></TopExperts>
            <NosExperts_V2></NosExperts_V2>
            <FooterBar></FooterBar>
        </div>
    );
}

