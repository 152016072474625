import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../../images/expert1.jpeg";


const Card = ({ rating, review, name, title, image }) => (
    <div className="p-4 bg-gray-100 mx-2 rounded-lg shadow-md">
        <div className="flex items-center mb-3">
            {Array.from({length: 5}, (_, i) => (
                <span key={i} className={i < rating ? 'text-yellow-400' : 'text-gray-300'}>★</span>
            ))}
        </div>
        <p className="h-52 overflow-hidden">{review}</p> {/* Limite à 3 lignes */}
        <div className="flex items-center mt-3">
            <img src={Image} alt={`${name}`} className="w-12 h-12 rounded-full mr-3"/>
            <div>
                <h4 className="font-semibold">{name}</h4>
                <p className="text-gray-600">{title}</p>
            </div>
        </div>
    </div>
);

const NextArrow = ({onClick}) => {
    return (
        <button
            className="text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600"
            onClick={onClick}
        >
            <svg className="h-5 w-5 justify-center items-center"
                 xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.99984 4.00012L10 8.00029L5.99748 12.0028" stroke="currentColor"
                      strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <button
            className="text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600"
            onClick={onClick}
        >
            <svg className="h-5 w-5 justify-center items-center"
                 xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M10.0002 11.9999L6 7.99971L10.0025 3.99719" stroke="currentColor"
                      strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    );
};


var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,  // Affiche 4 cartes au début
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ],
    // Utilisation de slickClone pour dupliquer les cartes si nécessaire
    slickClone: true
};

const CardCarousel = () => {
    //300 caractere

    const cards = [
        {
            rating: 4.5,
            review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec diam rutrum, fringilla neque vel, rutrum quam. Sed eu euismod ex. Proin sodales pharetra tellus, ' +
                'in fringilla eros tempor eu. Nulla facilisi. Mauris eu neque dignissim, convallis urna sed, semper augue. Cras pretium dui vel neque odio.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        },
        {
            rating: 5,
            review: 'A great little app that some of the big guns have clearly been taking their cue from. Looks like Incomee might actually be cleaner and simpler to use than competitors.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        },
        {
            rating: 5,
            review: 'A great little app that some of the big guns have clearly been taking their cue from. Looks like Incomee might actually be cleaner and simpler to use than competitors.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        },
        {
            rating: 2.5,
            review: 'A great little app that some of the big guns have clearly been taking their cue from. Looks like Incomee might actually be cleaner and simpler to use than competitors.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        },
        {
            rating: 5,
            review: 'A great little app that some of the big guns have clearly been taking their cue from. Looks like Incomee might actually be cleaner and simpler to use than competitors.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        },
        {
            rating: 5,
            review: 'A great little app that some of the big guns have clearly been taking their cue from. Looks like Incomee might actually be cleaner and simpler to use than competitors.',
            name: 'Craig Vaccaro',
            title: 'Founder and co-CEO',
            image: 'path_to_image' // Replace with actual path
        }
    ];

    return (
        <div className=" p-2 space-y-5 our-services w-5/6 md:w-3/4 mx-auto items-center justify-center ">
            <h1 className={" font-poppins text-3xl font-bold my-5 text-center text-gray-700"}>Des mots précieux de nos clients</h1>
            <div className="relative w-full mx-auto md:w-5/6 md:p-5 flex items-center justify-center">
                <div className="w-full">

                    {/*<Slider {...settings}>
                        {cards.map((card, index) => (
                            <Card key={index} {...card} />
                        ))}
                    </Slider>*/}
                    <div className="text-center text-gray-500 font-poppins">
                        Nous ne mettons pas des faux témoignages, laissez votre avis après consultation pour l'afficher ici!
                    </div>
                </div>
            </div>
        </div>


    );
};


export default CardCarousel;
