import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import axios from 'axios';
import backImgTel from "../../images/Union_tel2.png";
import backImg from "../../images/competencies.jpg";
import Navbar from "../Pages/Navbar";
// Fonction de simulation de vérification
const mockVerifyCredentials = (password) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (password === "password") {
                resolve({ status: 200 });
            } else {
                reject(new Error('Invalid credentials'));
            }
        }, 1000);
    });
};
const VerificationPopup = ({ open, onClose, onVerified, roomName, updateInfoVisioCall }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    // Function to extract and format the dateDebut and dateFin
    const formatDateTime = (jour, time) => {
        // Extract the date part
        const date = jour.split(" ")[0];
        // Combine date and time, and add the 'Z' suffix to indicate UTC time
        const dateTime = `${date}T${time}:00Z`;
        return dateTime;
    };
    const handleSubmit = async () => {
        /*try {
            // const response = await axios.post('/api/meetings/verify', { email, password });
            const response = await mockVerifyCredentials(password);
            if (response.status === 200) {
                onVerified();
            }
        } catch (error) {
            setError('Invalid credentials. Please try again.');
        }*/
        const body = {
            roomName: roomName,
            password: password,
        };
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL+"/infoVisioCall/validatePassword", body);
            onVerified();
            // Extract and format dateDebut and dateFin
            const dateDebut = formatDateTime(response.data.jour, response.data.debut);
            const dateFin = formatDateTime(response.data.jour, response.data.fin);
            updateInfoVisioCall(dateDebut,dateFin)
            //setResponseMessage(response.data); // Assuming response.data contains the message
        } catch (error) {
            if (error.response) {
                // The request was made, and the server responded with a status code
                // that falls out of the range of 2xx
                //setResponseMessage(`Error: ${error.response.data}`);
                // Handle different status codes
                switch (error.response.status) {
                    case 400:
                        setResponseMessage("Le mot de passe est invalide");
                        break;
                    case 410: // GONE
                        setResponseMessage("Le meet a expiré");
                        break;
                    case 404: // NOT FOUND
                        setResponseMessage("Il n'y a pas de meet avec ce nom");
                        break;
                    default:
                        setResponseMessage("Erreur: " + error.response.data);
                        break;
                }
            } else if (error.request) {
                // The request was made but no response was received
                setResponseMessage('Error: No response from server');
            } else {
                // Something happened in setting up the request that triggered an Error
                setResponseMessage(`Error: ${error.message}`);
            }
        }
    };
    const dialogStyles = {
        backgroundColor: '#A52A2A', // Changer la couleur de fond selon vos besoins
    };
    return (
        <>
            <style jsx>{`
                @media (max-width: 768px) {
                    .dialog-container {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }
                @media (min-width: 769px) {
                    .dialog-container {
                        background-image: url(${backImg});
                        background-size: cover;
                    }
                }
            `}</style>
            <Dialog open={open} onClose={onClose} className="dialog-container" fullWidth>
                <DialogTitle className="text-2xl font-bold text-center text-gray-800">
                    Entrer dans la réunion
                </DialogTitle>
                <DialogContent>
                    {responseMessage && <p className='text-red-500 mb-2'> {responseMessage} </p>}
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        className=" bg-gray-50 " placeholder="Saisir votre password"
                        required
                        margin="dense"
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default VerificationPopup;