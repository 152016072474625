import React from 'react';
import expertImg from "../images/expert8.jpeg";

function ExpertComponent({expert}) {
    return (
        <div
            className='flex flex-col max-h-full col-span-1 mb-5 justify-self-end mr-5 md:mr-11 items-center justify-center h-[32rem] pb-10 w-full md:w-[535px] bg-[length:auto_553px] drop-shadow-[0_14px_6px_rgba(0,0,0,0.1)]  bg-hero bg-no-repeat bg-center '
        >
            <img
                className="w-40 h-40 p-1 mb-3 rounded-full shadow-lg ring-2 ring-amber-300 dark:ring-amber-300"
                src={`data:image/jpeg;base64,${expert.photoExpert}`}
                alt="Bonnie image"
            />
            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-2A3F3F-">{expert.nomExpert} {expert.prenomExpert}</h5>
            <span className="text-md font-semibold text-1B3FA0 dark:text-1B3FA0">{expert.titreExpert}</span>
            <span className="text-sm font-medium mt-2 overflow-hidden w-72 line-clamp-4">
            {expert.descriptionExpert}
      </span>
            <div className="flex flex-col items-center mt-4 ">
                <span className="text-sm font-semibold text-1B3FA0 dark:text-1B3FA0 ">Tarif de consultation</span>
                <span
                    className="text-white text-center w-24 mt-2  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{expert.tarifParDefaut.tarif} MAD
        </span>
            </div>
            <div className="fixed bottom-0 flex justify-between m-4">
                <img className="w-6 h-6 rounded-full m-1"
                     src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
                     alt=""
                />
                <img className="w-6 h-6 rounded-full m-1"
                     src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Morocco.svg/langfr-1280px-Flag_of_Morocco.svg.png"
                     alt=""
                />
            </div>
        </div>
    );
}

export default ExpertComponent;

