import React, {useCallback, useEffect, useRef, useState} from 'react';
import backImg from '../../images/Union.png';
import backImgTel from '../../images/Union_tel2.png';
import Navbar from "./Navbar";
import {getExpertServicesById} from "../../Services/ListExpertService";
import {getDomaineService} from "../../Services/DomaineService";
import {getExpertsByService} from "../../Services/ExpertsByService";
import {Link, useNavigate} from "react-router-dom";
import {Select,Option} from "@material-tailwind/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import DomaineDropdown from "./DomaineDropdown";
import apiClient from "../../Services/apiService";
import {debounce} from "lodash";

export default function HeaderServices() {
    const [domaineServices, setDomaineServices] = useState([]);
    const [selectedDomaine, setSelectedDomaine] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState('');

    const handleSearch = async (event) => {
        event.preventDefault();
        if (suggestions.length > 0) {
            if (suggestions.length === 1) {
                // Redirigez vers la page du service avec l'ID du premier service trouvé
                const serviceId = suggestions[0].idService;
                navigate(`/service/${serviceId}`);
            }
            // Sinon, la liste des services sera affichée automatiquement
        } else {
            console.error('Aucun service trouvé');
            toast.warning('Aucun service trouvé : ' + searchTerm);
        }
    };

    const fetchSuggestions = async (value) => {
        try {
            // Appel à l'API pour obtenir les services correspondant au nom partiel
            const response = await apiClient.get('/services/byName', { params: { nomService: value } });
            setSuggestions(response.data);
            if (response.data.length === 0) {
                setError('Aucun service trouvé');
            }
        } catch (error) {
            console.error('Erreur lors de la recherche du service:', error);
            setError('Erreur lors de la recherche du service');
        }
    };

    // Utilisez useCallback pour mémoriser la fonction debounce
    const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 400), []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setError('');

        if (value.length >= 2) {
            debouncedFetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (serviceId) => {
        navigate(`/service/${serviceId}`);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                setDomaineServices(domainesService);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    return (
        <header
            className="relative pb-5  h-screen w-screen text-white md:bg-cover md:bg-bottom flex flex-col"
        >
            <style jsx>{`
                @media (max-width: 768px) {
                    header {
                        background-image: url(${backImgTel});
                        background-size: cover;
                        background-position: bottom;
                    }
                }

                @media (min-width: 769px) {
                    header {
                        background-image: url(${backImg});
                    }
                }
            `}</style>
            <Navbar/>
            <div
                className="w-full items-start mt-20 p-8 justify-center my-auto md:w-3/4 md:mx-auto font-montserrat flex-grow flex flex-col">
                <div className="text-2xl  xl:text-4xl">
                    <h1 className="font-montserrat font-extrabold  ">Choisissez notre service</h1>
                    <h1 className="font-montserrat font-extrabold ">pour obtenir des solutions</h1>
                    <h1 className="font-montserrat font-extrabold  ">sur mesure</h1>
                </div>
            </div>

            <form className="relative w-full flex p-4 flex-col items-center" onSubmit={handleSearch} autoComplete="off">
                <div>
                    <h1 htmlFor="default-search" className="mb-5 text-md font-semibold text-white">
                        Trouvez votre service ici :
                    </h1>
                </div>

                <div className="relative w-full md:w-96">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Recherche par services..."
                        required
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <button
                        type="submit"
                        className="text-white absolute end-2.5 bottom-2.5 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-full text-sm px-4 py-2"
                    >
                        Search
                    </button>
                    {suggestions.length > 0 && (
                        <div
                            id="dropdownDefaultCheckbox"
                            className="absolute mt-1 w-full md:w-96 bg-white border border-gray-300 rounded-lg shadow z-10"
                        >
                            <ul className="text-sm text-gray-700">
                                {suggestions.map(suggestion => (
                                    <li
                                        key={suggestion.idService}
                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => handleSuggestionClick(suggestion.idService)}
                                    >
                                        {suggestion.nomService} "<span className={"font-semibold"}>{suggestion.nomDomaine}</span>"
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </form>


            <div className="consulte flex p-6 justify-center bottom-0 items-center" id="Reserver">
                <div className="w-full flex flex-col  justify-center items-center">
                    <div>
                        <h1 htmlFor="default-search"
                            className="mb-5 text-md font-semibold text-white">Trouvez votre domaine ici :</h1>
                    </div>

                    <div>
                        <a href="#ServiceDetails"
                           className={" block text-white bg-green-600 w-75 font-poppins hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-500 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center"}>
                            Sélectionner votre domaine
                            <svg className="w-4 h-4 ms-3 animate-bounce" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="m1 1 4 4 4-4"/>
                            </svg>
                        </a>

                    </div>
                </div>
            </div>
        </header>


    );
}
