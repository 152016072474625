import React, {useEffect, useRef, useState} from 'react';
import {faDownload, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/carousel-custom.css";
import {Tooltip, Button, Typography} from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import {getExpertServicesById} from "../../Services/ListExpertService";
import {getExpertCertificatById} from "../../Services/ListExpertCertificat";
import {getExpertCompetenceById} from "../../Services/ListExpertCompetence";
import {getExpertFormationById} from "../../Services/ListExpertFormation";
import {getExpertExperienceById} from "../../Services/ListExpertExperience";
import expertImg from "../../images/expert8.jpeg";
import {getExperts} from "../../Services/ExpertService";


const ExpertCard = ({ expert }) => {
    const { nomExpert, prenomExpert, titreExpert, photoExpert } = expert;
    return (
        <>
            <button
                className="absolute py-1 px-3 -rotate-[20deg] text-[10px] border rounded-sm border-green-700  black_border bg-green-700 text-white font-bold">
                TOP EXPERT
            </button>
            <div
                className="bg-slate-100 w-full max-w-xs h-96 rounded-lg  border-2  border-solid   p-4 flex flex-col">

                <img
                    src={`data:image/jpeg;base64,${photoExpert}`}
                    alt={`${nomExpert} ${prenomExpert}`}
                    className="w-40 h-40 mt-2 p-1 rounded-full shadow-lg ring-2 ring-green-700 mx-auto mb-4"
                />
                <div className="flex flex-col justify-center items-center flex-grow">
                    <h2 className="text-lg font-poppins text-center font-semibold text-gray-800 mb-2">{`${nomExpert} ${prenomExpert}`}</h2>
                    <p className="text-teal-900 text-md  font-poppins font-semibold mb-4">{titreExpert}</p>
                    <p className="text-gray-600">{/*descriptionExpert*/}</p>
                </div>
                <div className="flex flex-row justify-center mt-auto space-x-2 items-center">
                    <div className="expert-info py-1">
                        <p className="small-title text-xs">
                            Expert Instruction
                            <span className="rating-star">
                            <FontAwesomeIcon icon={faStar} className="text-yellow-300"/>4.9
                        </span>
                        </p>
                    </div>
                    <div
                        className="flex invisible md:visible justify-center items-center text-white font-semibold text-xs rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-6 w-6 text-gray-500"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </>

    );
};

const TopExperts = () => {
    const [currentDomain, setCurrentDomain] = useState(null);
    const [currentExperts, setCurrentExperts] = useState([]);
    const [domaines, setDomaines] = useState([]);
    const [Loading, setLoading] = useState(false);
    const initialLoad = useRef(true); // Utilisation de useRef pour garder une trace du chargement initial
    const [showPrevNext, setShowPrevNext] = useState(false); // State to control visibility of prev/next arrows
    const [tooltipVisible, setTooltipVisible] = useState(false); // State to control tooltip visibility
    const [linkDisabled, setLinkDisabled] = useState(false);
    const [page, setPage] = useState(0);
    const [size] = useState(7); // Nombre d'experts par page
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const [expertCache, setExpertCache] = useState(new Map());
    const [experts, setExperts] = useState([]);

    useEffect(() => {
        const fetchInitialExperts = async () => {
            try {
                setLoading(true);

                // Appel à l'API pour récupérer la liste des experts
                const response = await getExperts(page, size);
                const newExperts = response.content;

                // Récupérer les détails des experts initiaux
                const expertsWithDetails = await Promise.all(newExperts.map(async (expert) => {
                    if (expertCache.has(expert.idExpert)) {
                        return expertCache.get(expert.idExpert);
                    } else {
                        const services = await getExpertServicesById(expert.idExpert);
                        const certificats = await getExpertCertificatById(expert.idExpert);
                        const competences = await getExpertCompetenceById(expert.idExpert);
                        const formations = await getExpertFormationById(expert.idExpert);
                        const experiences = await getExpertExperienceById(expert.idExpert);

                        const expertDetails = {
                            ...expert,
                            services,
                            certificats,
                            competences,
                            formations,
                            experiences,
                        };

                        // Mettre à jour le cache
                        expertCache.set(expert.idExpert, expertDetails);

                        return expertDetails;
                    }
                }));

                // Mettre à jour la liste des experts avec leurs détails
                setExperts(expertsWithDetails);
                setLoading(false);

                // Vérifier s'il reste plus d'experts à charger
                if (newExperts.length < size) {
                    setHasMore(false);
                }
            } catch (error) {
                console.error('Error fetching initial experts:', error);
                setLoading(false);
            }
        };

        fetchInitialExperts();
    }, []); // Recharger uniquement au montage initial





    const NextArrow = ({ onClick }) => {
        return (
            <button
                className="text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600"
                onClick={onClick}
            >
                <svg className="h-5 w-5 justify-center items-center"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M5.99984 4.00012L10 8.00029L5.99748 12.0028" stroke="currentColor"
                          strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <button
                className="text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600"
                onClick={onClick}
            >
                <svg className="h-5 w-5 justify-center items-center"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.0002 11.9999L6 7.99971L10.0025 3.99719" stroke="currentColor"
                          strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        );
    };


    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible); // Inverse la visibilité de l'infobulle
    };


    const settings = {
        infinite: true, // Set to true only if more than 1 expert
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay only if more than 1 expert
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true, // Set to true only if more than 1 expert
                    dots: true, // Show dots only if more than 1 expert
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true, // Set to true only if more than 1 expert
                    dots: true, // Show dots only if more than 1 expert
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                }
            }
        ]
    };



    return (
        <div className="flex flex-col p-2 our-services w-3/4 mx-auto items-center justify-center">
            <h2 className="title-service font-poppins my-4 text-3xl font-bold text-center text-gray-700">
                Top experts
            </h2>
            {Loading ? (
                <div className="relative p-5 mt-5">
                    <div className="absolute inset-0 bg-white bg-opacity-60 flex items-center justify-center">
                        <div className="flex items-center">
                            <span className="text-md font-poppins mr-4">Chargement...</span>
                            <svg className="animate-spin h-8 w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            ) : (
            <div className="relative w-full md:w-5/6 flex items-center justify-center">
                <PrevArrow onClick={() => document.querySelector('.slick-prev').click()} />
                <div className="w-full">
                    <Slider {...settings}>
                        {experts.map((expert, index) => (
                            <div key={index} className="px-2 flex py-5 items-center justify-center">
                                <Tooltip placement="top-start" animation="shift"
                                         className="border flex flex-col  border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                                         content={
                                             <div className="w-80">
                                                 <Typography color="blue-gray"
                                                             className="font-semibold text-base font-poppins text-black">
                                                     {expert.idExpert.nomExpert} {expert.idExpert.prenomExpert}
                                                 </Typography>
                                                 {expert.services && (
                                                     <Typography
                                                         variant="small"
                                                         color="blue-gray"
                                                         className="font-normal text-black opacity-80"
                                                     >

                                                         <span
                                                             className={"text-md font-bold text-teal-900"}>Services : </span>
                                                         {expert.services.map((service, index) => (
                                                             <span
                                                                 key={index}> {service.idService.nomService} |</span>
                                                         ))}
                                                     </Typography>
                                                 )}
                                                 {expert.certificats && (
                                                     <Typography
                                                         variant="small"
                                                         color="blue-gray"
                                                         className="font-normal text-black opacity-80"
                                                     >

                                                         <span className={"text-md font-bold text-teal-900"}>Certificats : </span>
                                                         {expert.certificats.map((certificat, index) => (
                                                             <span
                                                                 key={index}> {certificat.certificat.titreCertificat} |</span>
                                                         ))}
                                                     </Typography>
                                                 )}
                                                 {expert.competences && (
                                                     <Typography
                                                         variant="small"
                                                         color="blue-gray"
                                                         className="font-normal text-black opacity-80"
                                                     >

                                                         <span className={"text-md font-bold text-teal-900"}>Compétences : </span>
                                                         {expert.competences.map((competence, index) => (
                                                             <span
                                                                 key={index}> {competence.competence.titreCompetence} |</span>
                                                         ))}
                                                     </Typography>
                                                 )}
                                                 {expert.formations && (
                                                     <Typography
                                                         variant="small"
                                                         color="blue-gray"
                                                         className="font-normal text-black opacity-80"
                                                     >
                                                         <span className={"text-md font-bold text-teal-900"}>Formations : </span>
                                                         {expert.formations.map((formation, index) => (
                                                             <span
                                                                 key={index}> {formation.formation.titreFormation} |</span>
                                                         ))}
                                                     </Typography>
                                                 )}
                                                 {expert.experiences && (
                                                     <Typography
                                                         variant="small"
                                                         color="blue-gray"
                                                         className="font-normal text-black opacity-80"
                                                     >
                                                         <span className={"text-md font-bold text-teal-900"}>Expériences : </span>
                                                         {expert.experiences.map((experience, index) => (
                                                             <span key={index}>{experience.titreExperience}</span>
                                                         ))}
                                                     </Typography>
                                                 )}
                                             </div>
                                         }
                                >
                                    <Link to={`/expertSelected/${expert.idExpert}`}>
                                        <ExpertCard expert={expert}/>
                                    </Link>


                                </Tooltip>

                                {/* Infobulle qui s'affiche au clic */}
                                <Tooltip
                                    placement="top-start"
                                    animation="shift"
                                    className={`absolute border md:hidden border-blue-gray-50 bg-white px-4 shadow-xl shadow-black/10 ${tooltipVisible ? 'visible' : 'invisible'}`}
                                    content={(
                                        <div className="w-80">
                                            <Typography color="blue-gray"
                                                        className="font-semibold text-base font-poppins text-black">
                                                {expert.idExpert.nomExpert} {expert.idExpert.prenomExpert}
                                            </Typography>
                                            {expert.services && (
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal text-black opacity-80"
                                                >

                                                        <span
                                                            className={"text-md font-bold text-teal-900"}>Services : </span>
                                                    {expert.services.map((service, index) => (
                                                        <span key={index}> {service.idService.nomService} |</span>
                                                    ))}
                                                </Typography>
                                            )}
                                            {expert.certificats && (
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal text-black opacity-80"
                                                >

                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Certificats : </span>
                                                    {expert.certificats.map((certificat, index) => (
                                                        <span
                                                            key={index}> {certificat.certificat.titreCertificat} |</span>
                                                    ))}
                                                </Typography>
                                            )}
                                            {expert.competences && (
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal text-black opacity-80"
                                                >

                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Compétences : </span>
                                                    {expert.competences.map((competence, index) => (
                                                        <span
                                                            key={index}> {competence.competence.titreCompetence} |</span>
                                                    ))}
                                                </Typography>
                                            )}
                                            {expert.formations && (
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal text-black opacity-80"
                                                >
                                                        <span
                                                            className={"text-md font-bold text-teal-900"}>Formations : </span>
                                                    {expert.formations.map((formation, index) => (
                                                        <span
                                                            key={index}> {formation.formation.titreFormation} |</span>
                                                    ))}
                                                </Typography>
                                            )}
                                            {expert.experiences && (
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal text-black opacity-80"
                                                >
                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Expériences : </span>
                                                    {expert.experiences.map((experience, index) => (
                                                        <span key={index}>{experience.titreExperience}</span>
                                                    ))}
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                                >
                                    {/* Icône ou élément déclencheur de l'infobulle */}
                                    <div className=" md:hidden flex justify-center items-center relative">
                                        <button
                                            className=" flex items-center text-white font-semibold text-xs py-1 px-2 rounded-full"
                                            onClick={toggleTooltip}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                className="h-6 w-6 md:hidden text-gray-500"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                />
                                            </svg>
                                            <span
                                                className="text-black font-poppins text-md ml-1"> Plus d'informations </span>

                                        </button>

                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                    </Slider>
                </div>
                <NextArrow onClick={() => document.querySelector('.slick-next').click()} />
            </div>
                )}
        </div>
    );
};


export default TopExperts;
