import React from 'react';
import photoCongrat from "../../images/icon_Congratulation.png";
import Confirmation from '../Confirmation';

export default function CongratulationPage() {
    return (
        <Confirmation
            title="Félicitations !!"
            primaryMessage="Votre demande de consultation a été enregistrée avec succès."
            secondaryMessage="Veuillez consulter votre boîte mail pour obtenir de plus amples informations."
            tertiaryMessage="Veuillez patienter tandis que votre expert sélectionné examine et valide votre demande."
            imageSrc={photoCongrat}
            altText="icon Validate"
        />
    );
}