import { jwtDecode } from 'jwt-decode';


export default function decodeJwtToken(token){
    try {
        // Decode the JWT
        const decodedToken = jwtDecode(token);

        // Extract specific claims
        const firstName = decodedToken.firstName;
        const lastName = decodedToken.lastName;
        const email = decodedToken.email;
        const role = decodedToken.role;
        return {firstName,lastName,email,role};
    } catch (error) {
        console.error('Invalid token:', error);
    }

}