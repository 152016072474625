import HeaderExperts from "./HeaderExperts";
import HeaderSimple from "./HeaderSimple";
import React, {useEffect, useRef, useState} from "react";
import FooterBar from "./FooterBar";
import apiClient from "../../Services/apiService";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";


export default function Contact() {

    const nomCompletField = useRef();
    const emailField = useRef();
    const sujetField = useRef();
    const navigate = useNavigate();
    const messageField = useRef();
    const [isFormValid, setIsFormValid] = useState(false)
    const [error, setError] = useState({});
    const [sujetLength, setSujetLength] = useState(0);

    const [formData, setFormData] = useState({
        nomComplet: '',
        email: '',
        sujet: '',
        message: '',
    });

    const resetForm = () => {
        nomCompletField.current.value = '';
        emailField.current.value = '';
        sujetField.current.value = '';
        messageField.current.value = '';
        setFormData({
            nomComplet: '',
            email: '',
            sujet: '',
            message: '',
        });
    };

    const valideForm = () =>{
        const nomCompletValue = nomCompletField.current.value;
        const emailValue = emailField.current.value;
        const sujetValue = sujetField.current.value;
        const messageValue = messageField.current.value;

        let isFormValid = true;
        let errors = {};



        // Logique pour le champ nom
        if (nomCompletValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{nomComplet : 'Nom est obligatoire'}
            }));
            isFormValid = false;
        }else {
            setError(prevState => ({
                ...prevState,
                ...{nomComplet : ''}
            }));
        }


        // Logique pour le champ email
        if (emailValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{email : 'Email est obligatoire'}
            }));
            isFormValid = false;
        } else if (!emailValue.match(/^\S+@\S+\.\S+$/)) {
            setError(prevState => ({
                ...prevState,
                ...{email : 'Email est incorrect'}
            }));
            isFormValid = false;
        }else {
            setError(prevState => ({
                ...prevState,
                ...{email : ''}
            }));
        }

        // Logique pour le champ sujet
        if (sujetValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{subject : 'Sujet est obligatoire'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{subject : ''}
            }));
        }

        // Logique pour le champ sujet
        if (messageValue.trim().length < 30) {
            setError(prevState => ({
                ...prevState,
                ...{message : 'Sujet doit contenir au moins 30 caractères'}
            }));
            isFormValid = false;
        }  else {
            setError(prevState => ({
                ...prevState,
                ...{message : ''}
            }));
        }

        setIsFormValid(isFormValid)

        return isFormValid;
    }

    const getError = (fieldName) => {
        return error[fieldName];
    };

    const displayError = (fieldName) => {
        const erreur = getError(fieldName);
        if (erreur !== undefined && erreur !== '') {
            return <div className="text-xs block text-red-500">{erreur}</div>;
        }
    };

    const handleChange = (e) => {
        const messageValue = messageField.current.value;
        setSujetLength(messageValue.length);
        valideForm();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = valideForm();
        if (!isFormValid) {
            return; // Arrête l'exécution de la fonction si le formulaire n'est pas valide
        }


        // Récupérer les données du formulaire
        const contactData = {
            nomComplet: nomCompletField.current.value,
            email: emailField.current.value,
            sujet: sujetField.current.value,
            message: messageField.current.value,
        };

        try {
            // Envoi des informations du prospect et de la réservation au backend
            const response = await apiClient.post("/contact/ajouter", contactData);

            toast.success("Votre message a été envoyé avec succès.");
            // Rediriger l'utilisateur vers une autre page ou afficher un message de succès, etc.
            navigate("/congratulationPageContact");
            // Réinitialiser le formulaire après succès
            resetForm();
        } catch (error) {
            toast.error(`Une erreur est survenue : ${error.response.data}`);
            console.error("Erreur lors de la création du prospect ou de la réservation :", error);
        }
    };



    return (
        <>
            <HeaderSimple
                mainTitle="Contactez-Nous"
                subTitle="Besoin d'aide ou d'informations ? Nous sommes à votre écoute."
            ></HeaderSimple>
            <section className="bg-white p-5">
                <div className="  md:border-0 border border-gray-900/10 rounded-2xl p-5 px-4 mx-auto max-w-screen-md">
                    <form onSubmit={handleSubmit} className="space-y-8">
                        <h2 className="font-poppins font-bold text-2xl md:text-3xl text-center text-gray-700 mb-4 flex justify-center items-center">Formulaire de contact</h2>
                        <div>
                            <label htmlFor="nomComplet"
                                   className="block mb-2 text-sm font-medium text-gray-900 ">Votre nom complet</label>
                            <input type="text" id="nomComplet" name="nomComplet" ref={nomCompletField}
                                   onChange={handleChange}
                                   className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                                   placeholder=" Saisir votre nom et prenom"/>
                            {displayError('nomComplet')}
                        </div>
                        <div>
                            <label htmlFor="email"
                                   className="block mb-2 text-sm font-medium text-gray-900 ">Votre email</label>
                            <input type="email" id="email" name="email" ref={emailField} onChange={handleChange}
                                   className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                                   placeholder=" Saisir votre email"/>
                            {displayError('email')}
                        </div>
                        <div>
                            <label htmlFor="subject"
                                   className="block mb-2 text-sm font-medium text-gray-900 ">Sujet</label>
                            <input type="text" id="subject" name="subject" ref={sujetField} onChange={handleChange}
                                   className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
                                   placeholder="Dites-nous comment nous pouvons vous aider"/>
                            {displayError('subject')}
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message"
                                   className="block mb-2 text-sm font-medium text-gray-900 ">Votre message</label>
                            <textarea id="message" rows="6" name="message" ref={messageField} onChange={handleChange}
                                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                                      placeholder="Laissez un commentaire..."></textarea>

                            <div className="text-xs"><span>{sujetLength}</span> / 30 caractères</div>
                            {displayError('message')}
                        </div>
                        <button type="submit"
                                className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-teal-950 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Envoyer
                                                                                                                                                                                                             le
                                                                                                                                                                                                             message
                        </button>
                    </form>
                </div>
            </section>
            <FooterBar></FooterBar>
        </>
    );
}

