import photoProspect from '../images/ExpertConsult-01.png';
import axios from "axios";
import React, {Fragment, useEffect, useRef, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../Services/apiService";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import HeaderSimple from "./Pages/HeaderSimple";
import FooterBar from "./Pages/FooterBar";
import {value} from "lodash/seq";
import LoginPage from "./LoginPage";
import logoHbm from "../images/Logo_Les-Experts_FNL-10.png";

export default function DetailsProspect() {
    const location = useLocation();
    const reservationData = location.state && location.state.reservationData;
    const navigate = useNavigate();
    const [details, setDetails] = useState(null);
    const [sujetDomaine, setSujetDomaine] = useState(null);
    const [error, setError] = useState({});

    const [isOpen, setIsOpen] = useState(false); // État pour suivre si la pop-up est ouverte ou fermée

    const nomField = useRef();
    const prenomField = useRef();
    const telField = useRef();
    const emailField = useRef();
    const sujetField = useRef();
    const [sujetLength, setSujetLength] = useState(0);

    const [isFormValid, setIsFormValid] = useState(false)
    const [showPopup, setShowPopup] = useState(false);


    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        // Appeler la méthode pour récupérer les détails de la réservation lors du montage du composant
        fetchReservationDetails();
        // Appeler la méthode pour récupérer les détails du sujet domaine lors du montage du composant
        fetchSujetDomaine();
    }, []);



    useEffect(() => {
        setIsOpen(true);
        if (reservationData) {
            fetchReservationDetails();
        }
    }, [reservationData]);

    const fetchReservationDetails = async () => {
        try {
            const response = await apiClient.get(`/detailsCreneau/detailsReservation/${reservationData.idPlaningCreneau}`);
            setDetails(response.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchSujetDomaine = async () => {
        try {
            const response = await apiClient.get(`/sujetDomaine/list/${reservationData.idDomaine}`);
            // Utiliser la donnée récupérée et l'afficher
            console.log(response.data); // Affiche les données dans la console
            // Ou bien, vous pouvez les assigner à une variable d'état pour les utiliser dans votre composant
            setSujetDomaine(response.data);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchSujetDomaine();
    }, []);

    const reservationInfo = {
        expert:"Soufiane el fatehy",
        titre:"Developper Java",
        prix: "200dh",
        duree: "1 heure",
        date: "2024-05-10",
        heure: "15:00"
    };




    const [formData, setFormData] = useState({
        prenomProspect: "",
        nomProspect: "",
        emailProspect: "",
        telProspect: "",
        sujet: ""
    });
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Appel à la validation du formulaire
        const formValid = await valideForm();

        // Logique pour la longueur du sujet
        if (sujetField.current) {
            const sujetValue = sujetField.current.value;
            setSujetLength(sujetValue.length);
        }

    };

    const checkEmailExists = async (email) => {
        try {
            const response = await apiClient.post('/client/verifierClient', { emailClient: email });
            return response.data; // Retourne le message de la réponse
        } catch (error) {
            console.error('Erreur lors de la vérification de l\'email', error);
            return 'Erreur'; // Retourne un message d'erreur en cas d'exception
        }
    };

    const valideForm = async () => {
        const nomValue = nomField.current.value;
        const prenomValue = prenomField.current.value;
        const telValue = telField.current.value;
        const emailValue = emailField.current.value;
        const sujetValue = sujetField.current.value;

        let isFormValid = true;

        // Logique pour le champ nom
        if (nomValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{nomProspect: 'Nom est obligatoire'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{nomProspect: ''}
            }));
        }

        // Logique pour le champ prenom
        if (prenomValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{prenomProspect: 'Prenom est obligatoire'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{prenomProspect: ''}
            }));
        }

        // Logique pour le champ email
        if (emailValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                emailProspect: 'Email est obligatoire'
            }));
            isFormValid = false;
        } else if (!emailValue.match(/^\S+@\S+\.\S+$/)) {
            setError(prevState => ({
                ...prevState,
                emailProspect: 'Email est incorrect'
            }));
            isFormValid = false;
        } else {
            // Vérification si l'email existe
            const emailExists = await checkEmailExists(emailValue);
            if (emailExists === 'Client found') {
                setError(prevState => ({
                    ...prevState,
                    emailProspect: 'Email déjà utilisé.'
                }));
                toast.error(
                    <>
                        <span className={"text-gray-800"}>Cette adresse email est déjà utilisée par un autre client. Veuillez en choisir une autre.</span>
                    </>
                ); // Affiche l'alerte
                isFormValid = false;
            } else {
                setError(prevState => ({
                    ...prevState,
                    emailProspect: ''
                }));
            }
        }

        // Logique pour le champ sujet
        if (sujetValue.trim().length < 30) {
            setError(prevState => ({
                ...prevState,
                ...{sujet: 'Sujet doit contenir au moins 30 caractères'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{sujet: ''}
            }));
        }

        setIsFormValid(isFormValid);

        return isFormValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = await valideForm();
        if (!isFormValid) {
            return; // Arrête l'exécution de la fonction si le formulaire n'est pas valide
        }
        try {
            // Envoi des informations du prospect et de la réservation au backend
            const response = await apiClient.post("/reservation/add", {
                prospect: {
                    prenomProspect: formData.prenomProspect,
                    nomProspect: formData.nomProspect,
                    emailProspect: formData.emailProspect,
                    telProspect: formData.telProspect
                },
                reservationDto: {
                    ...reservationData,
                    datailDemandeReservationProspect: formData.sujet
                }
            });
            const prospectId = response.data;

            // Maintenant, vous pouvez utiliser prospectId pour faire des actions supplémentaires si nécessaire
            console.log("ID du prospect créé :", prospectId);
            toast.success("Réservation effectuée avec succès");
            // Rediriger l'utilisateur vers une autre page ou afficher un message de succès, etc.
            navigate("/congratulationPage");
            resetForm();
        } catch (error) {
            toast.error(`Une erreur est survenue : ${error.response.data}`);
            console.error("Erreur lors de la création du prospect ou de la réservation :", error);
        }
    };

    useEffect(() => {

        console.log(error)
    }, [error]);

    const getError = (fieldName) => {
        return error[fieldName];
    }
    const displayError = (fieldName) =>{
        const erreur = getError(fieldName);
        if (erreur !== undefined && erreur !== '') {
            return <div className="text-xs block text-red-500">{erreur}</div>;
        }
    }
    const displayErrors = () =>{
        return Object.entries(error).map((error, key) =>{
            const [field,message] = error
            return <li key={key}>{field} : {message}</li>
        })
    }
    const resetForm=()=>{
        nomField.current.value = '';
        prenomField.current.value ='';
        telField.current.value='';
        emailField.current.value='';
        sujetField.current.value='';
    }

    // État pour gérer la visibilité du mot de passe
    const [showPassword, setShowPassword] = useState(false);

    // Fonction pour basculer la visibilité du mot de passe
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };




    return (
        <>
            <HeaderSimple
                mainTitle="Formulaire de Réservation"
                subTitle={'Bienvenue ! '}
            ></HeaderSimple>
        <div className=" flex justify-center items-center w-full">

            <div className="w-full  md:w-3/4 p-5">
                <div className="space-y-12">
                    <div className="">
                        <div className=" mx-auto exp place-items-center md:w-2/3 text-center pb-3">
                            {/*<h1 className="font-poppins font-bold text-3xl  text-gray-700">Formulaire de Réservation</h1>*/}
                            <h3 className="font-montserrat font-normal">Merci de remplir le formulaire
                                                                        ci-dessous pour finaliser votre réservation.
                                                                        Vos informations nous aideront à mieux
                                                                        comprendre vos besoins et à planifier au
                                                                        mieux votre rendez-vous.
                            </h3>
                        </div>

                        <div className="flex flex-col md:flex-row items-center justify-center mt-3">

                            <div
                                className=" flex md:w-1/2 border border-gray-900/10 p-5 rounded-2xl  max-h-fit">

                                <form onSubmit={handleSubmit} className="w-full">
                                    <div className="">

                                        <h2 className="font-poppins font-bold text-xl  text-gray-700 mb-4 flex justify-center items-center">Détails
                                                                                                                                           de
                                                                                                                                           la
                                                                                                                                           réservation</h2>
                                        <div
                                            className="flex flex-row max-md:flex-col items-center justify-center col-span-full md:space-x-6">
                                            <div className="w-full">
                                                <div className="flex justify-between items-center mb-2">
                                                    <p className="font-poppins text-xs font-medium leading-6">Expert
                                                                                                              :</p>
                                                    <strong
                                                        className="font-poppins text-sm font-semibold leading-6 text-green-700">{reservationData.nomComplet}</strong>
                                                </div>
                                                <div className="flex justify-between items-center mb-2">
                                                    <p className="font-poppins text-xs font-medium leading-6">Specialité
                                                                                                              :</p>
                                                    <strong
                                                        className="font-poppins text-sm font-semibold leading-6 text-green-700">{reservationData.titreExpert}</strong>
                                                </div>
                                                <div className="flex justify-between items-center mb-2">
                                                    <p className="font-poppins text-xs font-medium leading-6">Prix de la
                                                                                                              séance
                                                                                                              :</p>
                                                    <strong
                                                        className="font-poppins text-sm font-semibold leading-6 text-green-700">{reservationData.prixTotal} DH</strong>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div className="flex justify-between items-center mb-2">
                                                    <p className="font-poppins text-xs font-medium leading-6">Durée de
                                                                                                              la
                                                                                                              séance
                                                                                                              :</p>
                                                    <strong
                                                        className="font-poppins text-sm font-semibold leading-6 text-green-700">{reservationData.duree} Min</strong>
                                                </div>
                                                {details && (
                                                    <div>
                                                        <div className="flex justify-between items-center mb-2">
                                                            <p className="font-poppins text-xs font-medium leading-6">Date
                                                                                                                      de
                                                                                                                      la
                                                                                                                      séance
                                                                                                                      :</p>
                                                            <strong
                                                                className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.jour}</strong>
                                                        </div>
                                                        <div className="flex justify-between items-center mb-2">
                                                            <p className="font-poppins text-xs font-medium leading-6">Heure
                                                                                                                      de
                                                                                                                      la
                                                                                                                      séance
                                                                                                                      :</p>
                                                            <strong
                                                                className="font-poppins text-sm font-semibold leading-6 text-green-700"> {details.heureDebut}</strong>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="w-full">
                                        <hr className="h-[2px] rounded w-full bg-gray-300"/>
                                    </div>
                                    <div
                                        className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 font-poppins">
                                        <div className="flex flex-row col-span-full space-x-4 ">
                                            <div className="w-full">
                                                <label htmlFor="first-name"
                                                       className="block text-xs font-medium leading-6 text-gray-500">
                                                    Prénom<span className="text-red-500">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="prenomProspect"
                                                        id="prenomProspect"

                                                        className={`block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${getError('prenomProspect') ? 'border-[1px] border-red-500' : ''}`}
                                                        onChange={handleChange}
                                                        ref={prenomField}
                                                        placeholder="Saisir votre prenom"
                                                        style={{fontSize: '12px'}}
                                                    />
                                                    {displayError('prenomProspect')}
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="last-name"
                                                       className="block text-xs font-medium leading-6 text-gray-500">
                                                    Nom de famille<span className="text-red-500">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="nomProspect"
                                                        id="nomProspect"

                                                        autoComplete="family-name"
                                                        className={`block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${getError('nomProspect') ? 'border-[1px] border-red-500' : ''}`}
                                                        onChange={handleChange}
                                                        ref={nomField}
                                                        placeholder="Saisir votre nom"
                                                        style={{fontSize: '12px'}}
                                                    />
                                                    {displayError('nomProspect')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row col-span-full space-x-4 ">
                                            <div className="w-full">
                                                <label htmlFor="last-name"
                                                       className="block text-xs font-medium leading-6 text-gray-500">
                                                    Téléphone
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="telProspect"
                                                        id="telProspect"
                                                        autoComplete="family-name"
                                                        className={`block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                                        onChange={handleChange}
                                                        ref={telField}
                                                        placeholder="Saisir votre telephone"
                                                        style={{fontSize: '12px'}}
                                                    />

                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="email"
                                                       className="block text-xs font-medium leading-6 text-gray-500">
                                                    Adresse e-mail<span className="text-red-500">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="emailProspect"
                                                        name="emailProspect"
                                                        type="text"
                                                        autoComplete="email"
                                                        className={`block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${getError('emailProspect') ? 'border-[1px] border-red-500' : ''}`}
                                                        onChange={handleChange}
                                                        ref={emailField}
                                                        placeholder="Saisir votre email"
                                                        style={{fontSize: '12px'}}
                                                    />
                                                    <div className={"flex flex-row space-x-5"}>
                                                        {displayError('emailProspect')}
                                                        {error.emailProspect === 'Email déjà utilisé.' && (
                                                            <a
                                                                href={"javascript:void(0);"}
                                                                className="font-poppins rounded-md underline text-xs block  font-semibold text-teal-900 shadow-sm"
                                                                onClick={() => setShowPopup(true)}
                                                            >
                                                                connectez-vous
                                                            </a>
                                                        )}
                                                    </div>
                                                    {showPopup && (
                                                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm">
                                                            <div
                                                                className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                                                                <div className="max-w-md w-full">
                                                                    <div
                                                                        className="relative p-8 rounded-2xl bg-gray-100 border border-teal-950 shadow-xl">
                                                                        <button
                                                                            type="button"
                                                                            className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none rounded-full"
                                                                            onClick={() => setShowPopup(false)}
                                                                            aria-label="Fermer"
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                viewBox="0 0 24 24"
                                                                                className="w-4 h-4"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth="2"
                                                                                    d="M6 18L18 6M6 6l12 12"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <h2 className="text-gray-800 font-poppins font-bold  text-center text-xl ">Accédez à Votre Compte</h2>
                                                                        <form className="mt-8 space-y-4">
                                                                            <div>
                                                                                <label
                                                                                    className="text-gray-800 text-sm mb-2 block">Email
                                                                                                                                 d'utilisateur</label>
                                                                                <div
                                                                                    className="relative flex items-center">
                                                                                    <input name="username" type="text"
                                                                                           required
                                                                                           className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                                                                                           placeholder="Saisissez votre email"/>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="#bbb" stroke="#bbb"
                                                                                        className="w-4 h-4 absolute right-4"
                                                                                        viewBox="0 0 24 24">
                                                                                        <circle cx="10" cy="7" r="6"
                                                                                                data-original="#000000"></circle>
                                                                                        <path
                                                                                            d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                                                            data-original="#000000"></path>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <label
                                                                                    className="text-gray-800 text-sm mb-2 block">
                                                                                    Mot de passe
                                                                                </label>
                                                                                <div
                                                                                    className="relative flex items-center">
                                                                                    <input
                                                                                        name="password"
                                                                                        type={showPassword ? "text" : "password"} // Change le type en fonction de l'état
                                                                                        required
                                                                                        className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                                                                                        placeholder="Entrer le mot de passe"
                                                                                    />
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill={showPassword ? "black" : "#bbb"} // Change la couleur en fonction de l'état
                                                                                        stroke={showPassword ? "black" : "#bbb"}
                                                                                        className="w-4 h-4 absolute right-4 cursor-pointer"
                                                                                        viewBox="0 0 128 128"
                                                                                        onClick={togglePasswordVisibility} // Appelle la fonction pour basculer la visibilité
                                                                                    >
                                                                                        {showPassword ? (
                                                                                            <path
                                                                                                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                                                            />
                                                                                        ) : (
                                                                                            <path
                                                                                                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                                                            />
                                                                                        )}
                                                                                    </svg>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="flex flex-wrap items-center justify-between gap-4">
                                                                                <div className="flex items-center">
                                                                                    <input id="remember-me"
                                                                                           name="remember-me"
                                                                                           type="checkbox"
                                                                                           className="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"/>
                                                                                    <label htmlFor="remember-me"
                                                                                           className="ml-3 block text-sm text-gray-800">
                                                                                        Souviens-toi de moi
                                                                                    </label>
                                                                                </div>
                                                                                <div className="text-sm">
                                                                                    <a href="jajvascript:void(0);"
                                                                                       className="text-green-700 hover:underline font-semibold">
                                                                                        Mot de passe oublié?
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <div className="!mt-8">
                                                                                <button type="button"
                                                                                        className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                                                    Se connecter
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-gray-800 text-sm !mt-8 text-center">Vous
                                                                                                                                   n'avez
                                                                                                                                   pas
                                                                                                                                   de
                                                                                                                                   compte
                                                                                                                                   ? <a
                                                                                    href="/register"
                                                                                    className="text-green-700 hover:underline ml-1 whitespace-nowrap font-semibold"> Inscrivez-vous
                                                                                                                                                                     ici</a>
                                                                            </p>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <label htmlFor="sujet"
                                                   className="block font-medium leading-6 text-gray-500 text-xs">
                                                Sujet de la consultation<span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    id="sujet"
                                                    name="sujet"
                                                    rows={7}

                                                    className={`block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${getError('sujet') ? 'border-[1px] border-red-500' : ''}`}
                                                    defaultValue={''}
                                                    onChange={handleChange}
                                                    ref={sujetField}
                                                    placeholder={`${sujetDomaine ? sujetDomaine.placeholderDomaine : ''}.`}
                                                    style={{fontSize: '12px'}}
                                                />
                                                <div className="text-xs"><span>{sujetLength}</span> / 30 caractères
                                                </div>
                                                {displayError('sujet')}
                                            </div>
                                            <p className="mt-3 text-xs leading-6 text-gray-600">Écrivez quelques phrases
                                                                                                sur vous-sujet.</p>
                                        </div>

                                    </div>
                                    <div className="mt-6 flex items-center justify-center gap-x-6">
                                        <button
                                            type="submit"
                                            className="rounded-md bg-green-600 w-full px-3 py-2 text-sm  font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Sauvegarder
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className=" hidden  md:w-1/2 pl-5 md:block">
                                <div className="w-full flex justify-center items-center">
                                    <img src={photoProspect} alt="Description de prospect"
                                         className="w-[520px] "/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        </div>
            <FooterBar></FooterBar>
        </>
    )
}