import photoProspect from '../images/ExpertConsult-01.png';
import axios from "axios";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import apiClient from "../Services/apiService";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import HeaderSimple from "./Pages/HeaderSimple";
import FooterBar from "./Pages/FooterBar";

export default function ValideRefusProspect() {
    const { id } = useParams(); // Get the reservation ID from the URL
    const location = useLocation();
    const reservationData = location.state && location.state.reservationData;
    const navigate = useNavigate();
    const [details, setDetails] = useState(null);
    const [error, setError] = useState({});
    const sujetField = useRef();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // État pour suivre si la pop-up est ouverte ou fermée
    const [clientIp, setClientIp] = useState('');

// Fetch reservation details when component mounts
    useEffect(() => {
        const fetchReservationDetails = async () => {
            try {
                const response = await apiClient.get(`/reservation/reservations/${id}`);
                setDetails(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des détails de la réservation :", error);
            }
        };
        fetchReservationDetails();
    }, [id]);

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setClientIp(response.data.ip);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'adresse IP :", error);
            }
        };

        fetchIp();
    }, []);

    async function getPaymentsLink(name, email, refDoc, prix, ip) {
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("product_reference", refDoc);
        formdata.append("ip",ip);
        formdata.append("amount", prix*100);
        formdata.append("applicationSource", "SharedSkills");
        const requestOptions = {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json', },
            body: formdata,
        };
        //await axios.post("https://0043-196-70-195-145.ngrok-free.app/youcanpay/", formdata)
        await axios.post(process.env.REACT_APP_PAIEMENT_URL, formdata)
            .then((response) => window.open(response.data, '_blank'))
            .catch((error) => console.log(error));
    }

    const handlePaymentClick = (e) => {
        e.preventDefault(); // Empêche le comportement par défaut du lien
        if (details.statutReservation === 'ATTENTE_PAIEMENT_PROSPECT') {
            getPaymentsLink(details.nomProspect, details.emailProspect, id, details.prix, clientIp);
        } else {
            switch (details.statutReservation) {
                case 'PENDING':
                    toast.error("Votre réservation est en attente. Veuillez patienter.");
                    break;
                case 'ANNULER_PROSPECT':
                    toast.error("Votre réservation a déjà été annulée.");
                    break;
                case 'ANNULER_PROSPECT_ALL_NOTIFIER':
                    toast.error("Votre réservation a déjà été annulée et notifiée.");
                    break;
                case 'RESERVATION_REJETER':
                    toast.error("Votre réservation a expiré.");
                    break;
                case 'PAIEMENT_PROSPECT_EFFECTUER':
                    toast.info("Votre réservation a déjà été payée.");
                    break;
                case 'PAIEMENT_PROSPECT_ALL_NOTIFIER':
                    toast.info("Votre réservation a déjà été payée et notifiée.");
                    break;
                default:
                    toast.error("Statut de réservation inconnu. Veuillez contacter le support.");
            }
        }
    };

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [formData, setFormData] = useState({
        sujet: ""
    });
    const handleChange = (e) => {
        valideForm();
        setFormData({ ...formData, [e.target.name]: e.target.value });

    };

    const valideForm = () =>{

        const sujetValue = sujetField.current.value;

        let isFormValid = true;

        // Logique pour le champ sujet
        if (sujetValue.trim() === '') {
            setError(prevState => ({
                ...prevState,
                ...{sujet : 'Motif est obligatoire'}
            }));
            isFormValid = false;
        } else {
            setError(prevState => ({
                ...prevState,
                ...{sujet : ''}
            }));
        }

        setIsFormValid(isFormValid)

        return isFormValid;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = valideForm();
        if (!isFormValid) {
            return; // Arrête l'exécution de la fonction si le formulaire n'est pas valide
        }
        try {
            // Ajoutez les données saisies par l'utilisateur à reservationData
            const updatedReservationData = {
                motif:formData.sujet
            };

            const reservation = await apiClient.post(`/reservation/annuler/prospect/${id}`, updatedReservationData);
            // Affichez les données mises à jour
            console.log("Données de la réservation mises à jour :", updatedReservationData);
            toast.success("Réservation annuler avec succès");

            // Rediriger l'utilisateur vers une autre page ou afficher un message de succès, etc.
            navigate("/refuseDemandePage");
            resetForm();
        } catch (error) {
            //alert(`Une erreur est survenue : ${error.response.data}`);
            toast.error(`Une erreur est survenue : ${error.response.data}`);
            console.error("Erreur lors de la création du prospect ou de la réservation :", error);
            // Gérer l'erreur
        }
    };
    useEffect(() => {
        console.log(error)
    }, [error]);

    const getError = (fieldName) => {
        return error[fieldName];
    }
    const displayError = (fieldName) =>{
        const erreur = getError(fieldName);
        if (erreur !== undefined && erreur !== '') {
            return <div className="text-xs block text-red-500">{erreur}</div>;
        }
    }
    const displayErrors = () =>{
        return Object.entries(error).map((error, key) =>{
            const [field,message] = error
            return <li key={key}>{field} : {message}</li>
        })
    }
    const resetForm=()=>{
        sujetField.current.value='';
    }




    return (
        <>
            <HeaderSimple
                mainTitle="Gérer vos Réservations"
                subTitle={<>Bienvenue <strong className={"font-montserrat font-bold"}>{details && details.nomProspect}</strong> !</>}
            ></HeaderSimple>
        <div className=" flex justify-center items-center w-full">

            <div className="w-full  md:w-3/4 p-5">
                <div className="space-y-12">
                    <div className="">
                        <div className="flex flex-col md:flex-row items-center justify-center mt-3">

                            <div
                                className=" flex md:w-[560px] w-full border border-gray-900/10 p-5 rounded-2xl  max-h-fit">

                                <form onSubmit={handleSubmit} className="w-full">
                                    <div className="">

                                        <h2 className="font-poppins font-bold text-xl  text-gray-700 mb-4 flex justify-center items-center">Détails
                                                                                                                                            de
                                                                                                                                            la
                                                                                                                                            réservation</h2>
                                        {details && (
                                            <div
                                                className="flex flex-col items-center justify-center col-span-full">
                                                <div className="w-full">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <p className="font-poppins text-xs font-medium leading-6">Expert
                                                                                                                  :</p>
                                                        <strong
                                                            className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.nomExpert}</strong>
                                                    </div>
                                                    <div className="flex justify-between items-center mb-2">
                                                        <p className="font-poppins text-xs font-medium leading-6">Specialité
                                                                                                                  :</p>
                                                        <strong
                                                            className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.titreExpert}</strong>
                                                    </div>
                                                    <div className="flex justify-between items-center mb-2">
                                                        <p className="font-poppins text-xs font-medium leading-6">Prix
                                                                                                                  de la
                                                                                                                  séance
                                                                                                                  :</p>
                                                        <strong
                                                            className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.prix} DH</strong>
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <p className="font-poppins text-xs font-medium leading-6">Durée
                                                                                                                  de
                                                                                                                  la
                                                                                                                  séance
                                                                                                                  :</p>
                                                        <strong
                                                            className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.duree} Min</strong>
                                                    </div>

                                                    <div>
                                                        <div className="flex justify-between items-center mb-2">
                                                            <p className="font-poppins text-xs font-medium leading-6">Date
                                                                                                                      de
                                                                                                                      la
                                                                                                                      séance
                                                                                                                      :</p>
                                                            <strong
                                                                className="font-poppins text-sm font-semibold leading-6 text-green-700">{details.jour}</strong>
                                                        </div>
                                                        <div className="flex justify-between items-center mb-2">
                                                            <p className="font-poppins text-xs font-medium leading-6">Heure
                                                                                                                      de
                                                                                                                      la
                                                                                                                      séance
                                                                                                                      :</p>
                                                            <strong
                                                                className="font-poppins text-sm font-semibold leading-6 text-green-700"> {details.heureDebut}</strong>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className="w-full">
                                        <hr className="h-[2px] rounded w-full bg-gray-300"/>
                                    </div>
                                    {isOpen && (
                                        <div>
                                            <div
                                                className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 font-poppins">
                                                <div className="col-span-full">
                                                    <label htmlFor="sujet"
                                                           className="block font-medium leading-6 text-gray-500 text-xs">
                                                        Motif de l'annulation<span className="text-red-500">*</span>
                                                    </label>
                                                    <div className="mt-2">
                                                <textarea
                                                    id="sujet"
                                                    name="sujet"
                                                    rows={7}
                                                    className={`block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-4 ${getError('sujet') ? 'border-[1px] border-red-500' : ''}`}
                                                    defaultValue={''}
                                                    onChange={handleChange}
                                                    ref={sujetField}
                                                    style={{fontSize: '12px'}}
                                                />
                                                        {displayError('sujet')}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-4 flex items-center justify-center gap-x-6">
                                                <button
                                                    type="submit"
                                                    className="rounded-md bg-red-600 w-full px-3 py-2 text-sm text-center  font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Annuler la reservation
                                                </button>
                                            </div>
                                            <div className="w-full mt-5 mb-3">
                                                <hr className="h-[2px] rounded w-full bg-gray-300"/>
                                            </div>
                                            <div className="grid grid-cols-1 items-center justify-center gap-x-6">
                                                <label htmlFor="sujet"
                                                       className="block font-medium leading-6 text-gray-500 text-xs">
                                                    {/*Si vous changez d'avis*/}
                                                    Vous pouvez toujours valider la réservation en continuant le processus de paiement.
                                                    {/*Si vous avez changé d'avis concernant l'annulation et que vous
                                                    souhaitez confirmer votre réservation, cliquez sur le bouton
                                                    ci-dessous.*/}
                                                </label>
                                                <div className={" w-full mt-2"}>
                                                    <a
                                                        href="#"

                                                        onClick={handlePaymentClick}
                                                       className="block rounded-md bg-green-600 w-full px-3 py-2 text-sm text-center font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Payez maintenant
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isOpen &&
                                        <div>
                                            <div className="grid grid-cols-1 mt-2 items-center justify-center gap-x-6">
                                            <label htmlFor="sujet"
                                                       className="block font-medium leading-6 text-gray-500 text-xs">
                                                    {/*Si vous changez d'avis*/}
                                                    Confirmer la réservation pour finaliser le processus.
                                                </label>
                                                <div className={" w-full mt-2"}>
                                                    <a
                                                        /*onClick={() => getPaymentsLink(details.nomProspect,details.emailProspect,id,details.prix,clientIp)}*/
                                                        onClick={handlePaymentClick}
                                                        className="block rounded-md bg-green-600 w-full px-3 py-2 text-sm text-center font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Payez maintenant
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 mt-2 items-center justify-center gap-x-6">
                                                <label htmlFor="sujet"
                                                       className="block font-medium leading-6 text-gray-500 text-xs">
                                                    {/*Si vous changez d'avis*/}
                                                    Si vous avez besoin d'annuler cette réservation, veuillez
                                                    cliquer
                                                    ci-dessous.
                                                </label>
                                                <button
                                                    onClick={openPopup}
                                                    className="rounded-md bg-red-600 mt-2 w-full px-3 py-2 text-sm text-center  font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Annuler la reservation
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                            <div className=" hidden  md:w-1/2 pl-0 md:block">
                                <div className="w-full flex justify-center items-center">
                                    <img src={photoProspect} alt="Description de prospect"
                                         className="w-[400px] "/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        </div>
            <FooterBar></FooterBar>
        </>
    )
}