import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import apiClient from "../../Services/apiService";

export default function NosServices() {


    const [services, setServices] = useState([]);

    useEffect(() => {
        apiClient.get('/services/list')
            .then(response => {
                setServices(response.data); // Assuming response.data is an array of services
            })
            .catch(error => {
                console.error('Error fetching services:', error);
            });
    }, []);

    return (
        <div className="flex flex-col our-services w-4/6 mx-auto items-center justify-center ">
            <h2 className="title-service font-poppins text-3xl font-bold my-5 text-gray-700">Nos services</h2>
            <div className="services-container items-center justify-center flex flex-row flex-wrap">
                {services.map((service, index) => (
                    <div key={index} className="service-card w-72 h-32 p-5 m-4 flex flex-col bg-gray-100 ">
                        <Link to={`/service/${service.idService}`} className="justify-center items-center service-link">
                            <div className="service-card  flex flex-row justify-center items-center">
                                <div className={"w-[130px] mr-10"}>
                                    <h3 className="font-poppins text-gray-700 font-semibold text-lg">{service.nomService}

                                    </h3>
                                </div>
                                <div>
                                    <img src="Icon1.png" alt={service.title} className="w-[80px] h-[80px]"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
                <div className="service-card w-72 h-32 p-5 m-4 flex items-center justify-center flex-col bg-gray-100 ">
                    <div className="service-card flex flex-row">
                            <div>
                                <Link to={`NosServices`} className="text-green-700 font-poppins text-2xl font-semibold">Plus de services</Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}