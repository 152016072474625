import React, {useEffect, useState} from "react";
import '../styles/NosDomains.css';
import {getDomaineService} from "../../Services/DomaineService";

export default function NosDomaines() {
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [domaineServices, setDomaineServices] = useState([]);
    const [showDomainesDropdown, setShowDomainesDropdown] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                setDomaineServices(domainesService);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    const domainesTexts = [
        {
            nomDomaine: "Banking Services",
            descriptionDomaine: "Become the most trusted Banker of your area"
        },
        {
            nomDomaine: "Digital Suite",
            descriptionDomaine: "Upgrade your business with 100% better credit management and digital payment tools"
        },

        {
            nomDomaine: "Digital Suite",
            descriptionDomaine: "Upgrade your business with 100% better credit management and digital payment tools"
        },
        {
            nomDomaine: "Digital Suite",
            descriptionDomaine: "Upgrade your business with 100% better credit management and digital payment tools"
        },

        {
            nomDomaine: "Digital Suite",
            descriptionDomaine: "Upgrade your business with 100% better credit management and digital payment tools"
        },
        {
            nomDomaine: "Digital Suite",
            descriptionDomaine: "Upgrade your business with 100% better credit management and digital payment tools"
        }
    ];
    const handleDomainChange = (event) => {
        const {value, checked} = event.target;
        if (checked) {
            setSelectedDomains(prevSelected => [...prevSelected, value]);
        } else {
            setSelectedDomains(prevSelected => prevSelected.filter(domain => domain !== value));
        }
    };

    return (
        <div className="text-center md:w-3/4 px-5 justify-center items-center mx-auto">
            <h2 className="title-domaine font-poppins text-3xl font-bold my-5 text-gray-700">Nos domaines </h2>
            <p className="mt-5 mx-auto max-w-xl font-poppins text-md font-normal my-5 text-center text-gray-700">
                Explorez nos domaines d'expertise pour trouver l'accompagnement dont vous avez besoin.
            </p>

            <div className={"flex flex-col md:flex-row  md:space-x-10 justify-center items-center"}>

                <div className="flex flex-col md:flex-row p-2 justify-center  md:items-start md:gap-8 md:mt-5">

                    <div className="flex flex-col items-center md:items-start md:gap-5">
                        {domaineServices.map((domaine, index) => (
                            index % 2 === 0 && (
                                <div key={index} className="flex justify-center items-center mb-5">
                                    <img src={`img_service.PNG`} alt={`image-${index}`} className="w-28 mx-2 h-28"/>
                                    <div className="w-64 h-24 domaines-item-left p-5 flex text-left items-center rounded-3xl ml-auto">
                                        <div className="text-left font-poppins">
                                            <strong className="block text-md font-bold text-black ml-2">
                                                {domaine.nomDomaine}
                                            </strong>
                                            <p className="text-xs font-medium text-gray-600 ml-2">
                                                {domaine.descriptionDomaine}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>

                    <div className="flex flex-col items-center md:items-end md:gap-5">
                        {domaineServices.map((domaine, index) => (
                            index % 2 !== 0 && (
                                <div key={index} className="flex justify-center items-center mb-5">
                                    <div className="w-64 h-24 domaines-item-right p-5 flex text-right items-center mr-auto rounded-3xl">
                                        <div className="text-left font-poppins">
                                            <strong className="block text-md font-bold text-black mr-2">
                                                {domaine.nomDomaine}
                                            </strong>
                                            <p className="text-xs font-medium text-gray-600 mr-2">
                                                {domaine.descriptionDomaine}
                                            </p>
                                        </div>
                                    </div>
                                    <img src={`img_service.PNG`} alt={`image-${index}`} className="w-28 mx-2 h-28"/>
                                </div>
                            )
                        ))}
                    </div>
                </div>

            </div>
        </div>

    )
}