import React, {useEffect, useState} from "react";
import apiClient from "../../Services/apiService";
import HeaderSimple from "./HeaderSimple";
import FooterBar from "./FooterBar";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {toast} from "react-toastify";

export default function PageListePlanningUpdate (){
    const [disponiblePlaningCreneaux, setDisponiblePlaningCreneaux] = useState([]);
    const [selectedCreneaux, setSelectedCreneaux] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);


    useEffect(() => {
        const fetchDisponiblePlaningCreneaux = async () => {
            try {
                const response = await apiClient.get('/detailsCreneau/expert/disponible/2'); // Remplacez '1' par l'ID de l'expert souhaité
                setDisponiblePlaningCreneaux(response.data);
                // Initialiser les créneaux sélectionnés
                const initialSelectedCreneaux = response.data
                    .filter(creneau => creneau.statutCreneau === 'DISPONIBLE')
                    .map(creneau => creneau.idPlaningCreneau);
                setSelectedCreneaux(initialSelectedCreneaux);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        fetchDisponiblePlaningCreneaux();
    }, []);

    const handleCheckboxChange = (idPlaningCreneau) => {
        setSelectedCreneaux((prevSelected) => {
            if (prevSelected.includes(idPlaningCreneau)) {
                return prevSelected.filter(id => id !== idPlaningCreneau);
            } else {
                return [...prevSelected, idPlaningCreneau];
            }
        });
    };

    const handleUpdateAvailability = async () => {
        try {
            const creneauxAEnvoyer = disponiblePlaningCreneaux.filter(creneau => {
                const estSelectionne = selectedCreneaux.includes(creneau.idPlaningCreneau);
                return (creneau.statutCreneau === 'DISPONIBLE' && !estSelectionne) ||
                    (creneau.statutCreneau === 'INDISPONIBLE' && estSelectionne);
            });

            const idsAEnvoyer = creneauxAEnvoyer.map(creneau => creneau.idPlaningCreneau);

            await apiClient.post('/detailsCreneau/miseAJourDisponibilite', {
                indisponibles: idsAEnvoyer.filter(id =>
                    disponiblePlaningCreneaux.find(creneau => creneau.idPlaningCreneau === id)?.statutCreneau === 'DISPONIBLE'
                ),
                disponibles: idsAEnvoyer.filter(id =>
                    disponiblePlaningCreneaux.find(creneau => creneau.idPlaningCreneau === id)?.statutCreneau === 'INDISPONIBLE'
                )
            });

            // Mettre à jour l'UI après une mise à jour réussie
            setDisponiblePlaningCreneaux(prevCreneaux =>
                prevCreneaux.map(creneau => {
                    if (creneauxAEnvoyer.some(c => c.idPlaningCreneau === creneau.idPlaningCreneau)) {
                        return {
                            ...creneau,
                            statutCreneau: creneau.statutCreneau === 'DISPONIBLE' ? 'INDISPONIBLE' : 'DISPONIBLE'
                        };
                    }
                    return creneau;
                })
            );

            setSelectedCreneaux([]);
            toast.success('Disponibilité mise à jour avec succès !');
            setOpenDialog(false);
            // Optionnel : recharger la page si nécessaire
            // window.location.reload();
            // Actualiser l'état checked après la mise à jour
            const refreshedCreneaux  = await apiClient.get('/detailsCreneau/expert/disponible/2'); // Remplacez '1' par l'ID de l'expert souhaité
            setDisponiblePlaningCreneaux(refreshedCreneaux .data);
            const initialSelectedCreneaux = refreshedCreneaux.data
                .filter(creneau => creneau.statutCreneau === 'DISPONIBLE')
                .map(creneau => creneau.idPlaningCreneau);
            setSelectedCreneaux(initialSelectedCreneaux);
        } catch (error) {
            console.error('Error updating availability:', error);
            toast.error('Erreur lors de la mise à jour de la disponibilité.');
        }
    };



    const handleClickOpen = () => {
            setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return(
        <>
            <HeaderSimple></HeaderSimple>
            <div className="p-3 flex justify-center items-center flex-col">
                <p className="font-poppins text-lg font-medium pb-4 text-center text-gray-700">Cochez les créneaux que vous souhaitez rendre indisponibles. Les
                                                  créneaux cochés deviendront indisponibles après confirmation.</p>
                <div
                    className="relative overflow-x-auto   shadow-md rounded-lg md:w-3/4 w-full flex mx-auto  ">

                    <table className="w-full text-sm text-center rounded-2xl rtl:text-right text-gray-500 ">
                        <thead className=" text-xs text-gray-700  uppercase bg-gray-200 ">
                        <tr>
                            <th scope="col" className=" border px-10 py-3">
                                Jour
                            </th>
                            <th scope="col" className=" border px-10 py-3">
                                Semaine
                            </th>

                            <th scope="col" className="border px-6 py-3">
                                Heure Début
                            </th>
                            <th scope="col" className="border px-6 py-3">
                                Heure Fin
                            </th>
                            <th scope="col" className="border px-6 py-3">
                                statut
                            </th>
                            <th>Disponibilité</th>
                        </tr>
                        </thead>
                        <tbody>
                        {disponiblePlaningCreneaux.map((creneau) => (
                            <tr className="bg-white font-semibold border-b " key={creneau.idPlaningCreneau}>
                                <td className={"border px-4 py-2"}>{creneau.jour}</td>
                                <td className="border px-4 py-2">{creneau.semaine}</td>

                                <td className="border px-4 py-2">{creneau.idCreneau.heureDebut}</td>
                                <td className="border px-4 py-2">{creneau.idCreneau.heureFin}</td>
                                {/*<td className="border px-4 py-2"><input id="default-checkbox" type="checkbox"
                                                                        value=""
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                </td>*/}
                                <td className={`border px-4 py-2`}>
                                    <span
                                        className={`text-green-700 font-poppins font-semibold ${creneau.statutCreneau === 'INDISPONIBLE' ? 'text-red-700' : 'text-green-700'} rounded-full py-1 w-28 text-g`}
                                        style={{display: 'inline-block'}}
                                    >
                                        {creneau.statutCreneau}
                                    </span>
                                </td>
                                <td className="border px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedCreneaux.includes(creneau.idPlaningCreneau)}
                                        onChange={() => handleCheckboxChange(creneau.idPlaningCreneau)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
                <button onClick={handleClickOpen} className="mt-6 bg-teal-900 text-white py-2 px-4 rounded-lg">
                    Mettre à jour la disponibilité
                </button>

                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Êtes-vous sûr de vouloir rendre ces créneaux indisponibles ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleUpdateAvailability} color="primary" autoFocus>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
            <FooterBar></FooterBar>
        </>
    )
}