import { useEffect, useState } from "react";
import MeetComponent from "./MeetComponent";

const JitsiMeet = ({ dateDebut, dateFin, roomName, jwt }) => {
  const [isMeetAvailable, setIsMeetAvailable] = useState(false);

  useEffect(() => {
    const checkDateCondition = () => {
      const now = new Date();
      // Add one hour
      now.setHours(now.getHours() + 1);
      const start = new Date(dateDebut);
      // Subtract 10 minutes (600,000 milliseconds) from the start date
      const targetDate = new Date(start.getTime() - 10 * 60 * 1000);

      if (now > targetDate) {
        setIsMeetAvailable(true);
      } else {
        setIsMeetAvailable(false);
      }
    };

    checkDateCondition();
  }, [dateDebut]);





  return (
    <>
      {
        isMeetAvailable ? <MeetComponent token={jwt} roomName={roomName} dateDebut={dateDebut} dateFin={dateFin} /> :
          <div class="flex flex-col items-center justify-center w-full h-full bg-gradient-to-r from-green-700/10  to-white/30 animate-gradient-x text-white rounded-lg shadow-lg p-8 mx-auto font-[sans-serif]">
            <h2 class="text-2xl font-bold text-center text-white">
              Préparez-vous pour la réunion !
            </h2>
            <p class="mt-4 text-lg font-italic text-white text-center" behavior="scroll" direction="left" scrollamount="3">
              La réunion sera disponible 10 minutes avant l'heure de début.
            </p>
          </div>
        /*<MeetComponent token={jwt} roomName={roomName} dateDebut={dateDebut} dateFin={dateFin} />*/
      }
    </>
  )
}

export default JitsiMeet;