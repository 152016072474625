import React from 'react';
import PropTypes from "prop-types";
//import photoCongrat from "../images/icon_Congratulation.png";

export default function Confirmation({ title, primaryMessage, secondaryMessage, tertiaryMessage, imageSrc, altText }) {
    return (
        <div className="flex justify-center items-center flex-col mx-auto my-auto w-full h-screen">
            <div className="pb-5">
                <div>
                    <img src={imageSrc} alt={altText} />
                </div>
            </div>
            <div className="flex flex-col justify-center items-center w-3/4">
                <h1 className="font-montserrat font-semibold text-5xl text-gray-700 p-2">{title}</h1>
                <h3 className="font-montserrat font-medium text-green-700 text-2xl p-1">{primaryMessage}</h3>
                {secondaryMessage && (
                    <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">{secondaryMessage}</h3>
                )}
                {tertiaryMessage && (
                    <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">{tertiaryMessage}</h3>
                )}
            </div>
        </div>
    );
}

Confirmation.propTypes = {
    title: PropTypes.string.isRequired,
    primaryMessage: PropTypes.string.isRequired,
    secondaryMessage: PropTypes.string,
    tertiaryMessage: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
};



