import React, {useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import apiClient from "../Services/apiService";
import HeaderSimple from "./Pages/HeaderSimple";
import FooterBar from "./Pages/FooterBar";
import '../index.css';

export default function PageListePlanningUpdate() {
    const [disponiblePlaningCreneaux, setDisponiblePlaningCreneaux] = useState([]);
    const [selectedCreneaux, setSelectedCreneaux] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [planningCreneaus, setPlanningCreneaus] = useState({});
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const fetchPlanningInterval = useRef();
    const [id, setId] = useState(null); // Déclare l'ID comme état pour qu'il soit accessible partout


    const userEmail = localStorage.getItem('userEmail');

    useEffect(() => {
        const fetchDisponiblePlaningCreneaux = async () => {
            try {
                const idExpertResponse  = await apiClient.post("/experts/id",{
                    email : userEmail
                })
                const expertId = idExpertResponse.data;
                setId(expertId); // Stocker l'ID expert dans l'état

                //const response = await apiClient.get(`/detailsCreneau/expert/disponible/${id}`) // Replace '1' with the desired expert ID
                const response = await apiClient.get(`/detailsCreneau/expert/disponible/${expertId}`) // Replace '1' with the desired expert ID
                setDisponiblePlaningCreneaux(response.data);
                const initialSelectedCreneaux = response.data
                    .filter(creneau => creneau.statutCreneau === 'DISPONIBLE')
                    .map(creneau => creneau.idPlaningCreneau);
                setSelectedCreneaux(initialSelectedCreneaux);

                const planningMap = response.data.reduce((acc, creneau) => {
                    const dateKey = creneau.jour;
                    if (!acc[dateKey]) {
                        acc[dateKey] = [];
                    }
                    acc[dateKey].push(creneau);
                    return acc;
                }, {});
                setPlanningCreneaus(planningMap);

                // Set the unit value for the expert
                if (response.data.length > 0) {
                    setUnit(response.data[0].idCreneau.unite.valeurEnMinutes);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDisponiblePlaningCreneaux();

        // Définition de l'intervalle de rafraîchissement
        /*fetchPlanningInterval.current = setInterval(fetchDisponiblePlaningCreneaux, 120000);*/
    }, []);

    const [unit, setUnit] = useState(30); // Default unit value

    const handleCheckboxChange = (idPlaningCreneau) => {
        setSelectedCreneaux((prevSelected) => {
            if (prevSelected.includes(idPlaningCreneau)) {
                return prevSelected.filter(id => id !== idPlaningCreneau);
            } else {
                return [...prevSelected, idPlaningCreneau];
            }
        });
    };

    const handleUpdateAvailability = async () => {
        try {
            const creneauxAEnvoyer = disponiblePlaningCreneaux.filter(creneau => {
                const estSelectionne = selectedCreneaux.includes(creneau.idPlaningCreneau);
                return (creneau.statutCreneau === 'DISPONIBLE' && !estSelectionne) ||
                    (creneau.statutCreneau === 'INDISPONIBLE' && estSelectionne);
            });

            const idsAEnvoyer = creneauxAEnvoyer.map(creneau => creneau.idPlaningCreneau);

            await apiClient.post('/detailsCreneau/miseAJourDisponibilite', {
                indisponibles: idsAEnvoyer.filter(id =>
                    disponiblePlaningCreneaux.find(creneau => creneau.idPlaningCreneau === id)?.statutCreneau === 'DISPONIBLE'
                ),
                disponibles: idsAEnvoyer.filter(id =>
                    disponiblePlaningCreneaux.find(creneau => creneau.idPlaningCreneau === id)?.statutCreneau === 'INDISPONIBLE'
                )
            });

            setDisponiblePlaningCreneaux(prevCreneaux =>
                prevCreneaux.map(creneau => {
                    if (creneauxAEnvoyer.some(c => c.idPlaningCreneau === creneau.idPlaningCreneau)) {
                        return {
                            ...creneau,
                            statutCreneau: creneau.statutCreneau === 'DISPONIBLE' ? 'INDISPONIBLE' : 'DISPONIBLE'
                        };
                    }
                    return creneau;
                })
            );

            setSelectedCreneaux([]);
            toast.success('Disponibilité mise à jour avec succès !');
            setOpenDialog(false);

            const refreshedCreneaux = await apiClient.get(`/detailsCreneau/expert/disponible/${id}`); // Replace '1' with the desired expert ID
            setDisponiblePlaningCreneaux(refreshedCreneaux.data);
            const initialSelectedCreneaux = refreshedCreneaux.data
                .filter(creneau => creneau.statutCreneau === 'DISPONIBLE')
                .map(creneau => creneau.idPlaningCreneau);
            setSelectedCreneaux(initialSelectedCreneaux);

            const planningMap = refreshedCreneaux.data.reduce((acc, creneau) => {
                const dateKey = creneau.jour;
                if (!acc[dateKey]) {
                    acc[dateKey] = [];
                }
                acc[dateKey].push(creneau);
                return acc;
            }, {});
            setPlanningCreneaus(planningMap);



        } catch (error) {
            console.error('Error updating availability:', error);
            toast.error('Erreur lors de la mise à jour de la disponibilité.');
        }
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const renderDays = () => {
        const days = [];
        const startDay = new Date(startDate);


        for (let i = 0; i < 30; i++) {
            const date = new Date(startDay);
            date.setDate(startDay.getDate() + i);
            const day = date.toLocaleString('default', { weekday: 'short' }).toLowerCase();
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
            const dateKey = date.toISOString().split('T')[0]; // Utiliser le format ISO pour comparer les jours

            days.push(
                <div key={dateKey} className="flex  flex-row bg-white  text-sm font-semibold">
                    <div className={"Date pl-4 pr-2 flex mb-3 flex-row"}>
                        <div className={`text-center ${day === 'sam.' || day === 'dim.' ? 'text-red-500' : ''}`}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                        </div>
                        <div className="text-xs ml-1">{formattedDate}</div>
                    </div>
                </div>
            );
        }
        return days;
    };



    const renderPlanning = () => {
        const allHoursOfDay = [];
        for (let hour = 9; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += unit) {
                const formattedHour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                allHoursOfDay.push(formattedHour);
            }
        }

        return (
            <div className="flex space-y-1 justify-center flex-col w-full text-green-500 ">
                {renderDays().map((day, index) => {
                    const dateKey = day.key; // Utiliser la clé comme identifiant de la date
                    return (
                        <div key={index} className="flex space-x-2 flex-row px-1">
                            {allHoursOfDay.map((hour) => {
                                const creneau = planningCreneaus[dateKey]?.find(creneau => creneau.idCreneau.heureDebut === hour);
                                const idPlaningCreneau = creneau ? creneau.idPlaningCreneau : null;
                                let buttonClass = 'border py-px w-[45px] md:w-16 h-6 mb-1 mt-0 rounded font-medium text-sm ';
                                const focusStyle = 'border-2A3F3F bg-green-700 text-white outline-none ring-2 ring-bleu-400 hover:border-black hover:bg-red-600 hover:text-white focus:bg-green-700 focus:text-white';

                                if (!creneau) {
                                    buttonClass += 'line-through border-gray-300 bg-gray-200 text-gray-600 cursor-not-allowed';
                                } else if (selectedCreneaux.includes(creneau.idPlaningCreneau)) {
                                    buttonClass += focusStyle;
                                } else {
                                    buttonClass += 'border-red-500 bg-red-600 text-white hover:border-black hover:bg-green-700 hover:text-white focus:bg-red-600 focus:text-white ';
                                }

                                return (
                                    <button
                                        key={hour}
                                        value={idPlaningCreneau}
                                        className={buttonClass}
                                        disabled={!creneau || !creneau.statutCreneau === 'DISPONIBLE' || !creneau.statutCreneau === 'INDISPONIBLE'}
                                        title={
                                            creneau
                                                ? creneau.statutCreneau === 'DISPONIBLE'
                                                    ? 'Rendre ce créneau non disponible'
                                                    : creneau.statutCreneau === 'INDISPONIBLE'
                                                        ? 'Rendre ce créneau disponible'
                                                        : 'Statut du créneau inconnu'
                                                : 'Aucun créneau sélectionné'
                                        }
                                        onClick={() => handleCheckboxChange(creneau.idPlaningCreneau)}
                                    >
                                        {hour}
                                    </button>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <HeaderSimple></HeaderSimple>
            <div className="p-3 flex justify-center w-full md:w-5/6 m-auto items-center flex-col">
                <p className="font-poppins text-md w-3/4 whitespace-pre-line font-medium mb-5 text-center text-gray-800">
                    Les heures disponibles sont en <span className="font-bold uppercase text-green-700">vert</span> et
                    les heures indisponibles en <span className="font-bold uppercase text-red-600">rouge</span>.
                    Pour changer une disponibilité, cliquez sur l'heure que vous avez déjà ajoutée, puis cliquez
                    sur <span className="font-bold text-teal-900">"Mettre à jour la disponibilité"</span>. Cela
                    vous permettra de gérer facilement votre planning.
                </p>
                <div
                    className="relative border-1 shadow-[0_2px_8px_-1px_rgba(0,0,0,0.3)] rounded-xl pt-4  h-[500px] overflow-auto flex max-w-full md:max-w-5/6">
                    <div className="sticky flex flex-col  mr-2 font-semibold  left-0 bg-white z-10">
                        <div className={""}>
                            {renderDays()}
                        </div>
                    </div>
                    <div className="flex w-full items-start flex-grow">
                        <div className="flex space-x-3">
                            {renderPlanning()}
                        </div>
                    </div>
                </div>
                <button onClick={handleClickOpen} className="mt-6 bg-teal-900 text-white py-2 px-4 rounded-lg">
                    Mettre à jour la disponibilité
                </button>
            </div>
            <Dialog open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Confirmation de mise à jour de la disponibilité"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir mettre à jour la disponibilité des créneaux sélectionnés ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleUpdateAvailability} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            <FooterBar></FooterBar>
        </>
    );
};