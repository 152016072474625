import React, { useState } from 'react';
import FooterBar from "./FooterBar";
import HeaderServices from "./HeaderServices";
import NosDomaines from "./NosDomaines";
import NosServicesDetails from "./NosServicesDetails";

export default function PageNosServices() {
    return (
        <div className="">
            <HeaderServices></HeaderServices>
            <NosDomaines></NosDomaines>
            <NosServicesDetails></NosServicesDetails>
            <FooterBar></FooterBar>
        </div>
    );
}

