import axios from 'axios';

// Crée une instance axios avec des paramètres de configuration par défaut
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // URL de base de votre API
    headers: {
        'Content-Type': 'application/json', // Définit le type de contenu par défaut pour les requêtes
    },
});
export default apiClient;
/*// Exemple d'utilisation avec l'URL complète
const url1 = 'process.env.REACT_APP_BACKEND_URL';
const url2 = 'http://192.168.11.102:8080';*/

/*// Exemple d'appel à l'API avec la première URL
apiClient.get(`${url1}`)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });

// Exemple d'appel à l'API avec la deuxième URL
apiClient.get(`${url2}`)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });


/!*
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'process.env.REACT_APP_BACKEND_URL', // Utiliser la variable d'environnement ou fallback à localhost
    headers: {
        'Content-Type': 'application/json', // Définit le type de contenu par défaut pour les requêtes
    },
});*!/*/
