import photoSorry from "../images/icon_Rejected.png";
import Confirmation from "./Confirmation";
import React from "react";
import {Link} from "react-router-dom";

const Unauthorized = () => {
    return (
        /*<Confirmation
            title="Accès refusé"
            primaryMessage="Vous n'avez pas les autorisations nécessaires pour accéder à cette page."
            secondaryMessage="Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre support."
            tertiaryMessage="Merci de votre compréhension."
            imageSrc={photoSorry} // Vous pouvez définir une image spécifique pour l'accès refusé
            altText="icon Unauthorized"
        />*/
    <div className="flex justify-center items-center flex-col mx-auto my-auto w-full h-screen">
        <div className="pb-5">
            <div>
                <img src={photoSorry} alt={"icon Unauthorized"}/>
            </div>
        </div>
        <div className="flex flex-col justify-center items-center w-3/4">
            <h1 className="font-montserrat font-semibold text-5xl text-gray-700 p-2">Accès refusé</h1>
            <h3 className="font-montserrat font-medium text-green-700 text-2xl p-1">Vous n'avez pas les autorisations nécessaires pour accéder à cette page.</h3>
            <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre support.</h3>
            <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">Merci de votre compréhension.</h3>
        </div>
        <div className="flex flex-col items-center justify-center">
            <Link to='/'
                  className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
                  title="Return Home">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                     fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"></path>
                </svg>
                <span>Return Home</span>
            </Link>
        </div>
    </div>
)
    ;
};
export default Unauthorized;