import React, { useState } from 'react';

export default function Commentfonctionne() {
    return (
        <div className="contact-us md:w-2/4 p-4 mx-auto">
            <h2 className="title-service font-poppins text-3xl font-bold my-5 text-center text-gray-700">Comment fonctionne "HBM Solutions" ? </h2>
    <p className="subtitle-cont font-poppins text-lg font-medium my-5 text-center text-gray-700">Prenez un rendez-vous pour une consultation rapide en 5 étapes :</p>
            <img src="schema_fonc.PNG" alt="Contact Us" className="contact-image" />
        </div>
    );
}