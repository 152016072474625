

// Exemple de fonction de récupération de données
import axios from 'axios';
import apiClient from "./apiService";

export const getExpertExperienceById = async (idExpert) => {
    try {
        const response = await apiClient.get(`/experience/expert/${idExpert}`, {
            headers: {
                'Access-Control-Allow-Origin': process.env.REACT_APP_URL // Permettre les requêtes depuis le port 3000 (adresse locale du client React)
            }
        });
        return response.data;
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la récupération des données :', error);
        throw new Error('Une erreur s\'est produite lors de la récupération des données.');
    }
};

