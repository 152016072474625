import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Switch, BrowserRouter} from 'react-router-dom';

import ExpertsServices_v3 from "./components/ExpertsServices_v3";
import DetailsProspect from "./components/DetailsProspect";
import Confirmation from "./components/Confirmation";
import RefusProspect from "./components/RefusProspect";
import RefusExpert from "./components/RefusExpert";
import ValideRefusProspect from "./components/ValideRefusProspect";
import ValideRefusExpert from "./components/ValideRefusExpert";
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CongratulationPage from "./components/ConfirmationPages/CongratulationPage";
import SorryPage from "./components/ConfirmationPages/SorryPage";
import RefuseDemandePage from "./components/ConfirmationPages/RefuseDemandePage";
import ValidateDemandePage from "./components/ConfirmationPages/ValidateDemandePage";
import Navbar from "./components/Pages/Navbar";
import Header from "./components/Pages/Header";
import Accueil from "./components/Pages/Accueil";
import ExpertsByService from "./components/ExpertsByService";
import ExpertSelectedId from "./components/ExpertSelectedId";
import ScrollToTop from "./components/Pages/ScrollToTop";
import PageNotFound from "./components/Pages/PageNotFound";
import PageNosServices from "./components/Pages/PageNosServices";
import PageNosExperts from "./components/Pages/PageNosExperts";
import Contact from "./components/Pages/Contact";
import CongratulationPageContact from "./components/ConfirmationPages/CongratulationPageContact";
import PageMeet from "./components/Pages/PageMeet";
import JitsiMeetComponent from "./components/Pages/JitsiMeetComponent";
import PageListePlanning from "./components/Pages/PageListePlanning";
import PageListePlanningUpdate from "./components/Pages/PageListePlanningUpdate";
import PlanningUpdateComponent from "./components/PlanningUpdateComponent";
import ClientFeedback from "./components/ClientFeedback";
import ExpertFeedback from "./components/ExpertFeedback";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import RequireAuth from "./components/RequireAuth";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import RequireRole from "./components/RequireRole";
import Unauthorized from "./components/Unauthorized";

export default function App() {
    const backImg = '../../images/Union.png'; // Remplacez par le chemin de votre image
    const backImgTel = '../../images/Union_tel2.png'; // Remplacez par le chemin de votre image mobile


    const firstName = localStorage.getItem('userFirstName');
    const lastName = localStorage.getItem('userLastName');
    const email = localStorage.getItem('userEmail');
    const role = localStorage.getItem('role');

    const signOut = useSignOut()
    function redirectTo(url) { window.location.href = url; }
    const isSessionExpired = () => {
        const sessionExpireAtString = localStorage.getItem('session_expire_at');
        if (!sessionExpireAtString) {
            return false;
        }
        const sessionExpireAt = new Date(sessionExpireAtString);
        const now = new Date();
        return now > sessionExpireAt;
    }
    const handleSession = () => {
        if (isSessionExpired()) {
            signOut()
            window.localStorage.setItem("lastRoute",window.location.pathname)
            window.localStorage.removeItem("session_expire_at")
            window.localStorage.removeItem('userFirstName'); // Supprimer les informations utilisateur
            window.localStorage.removeItem('userLastName');
            window.localStorage.removeItem('userEmail');
            window.localStorage.removeItem('role');
            redirectTo("/login")
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('check-expiration', isSessionExpired())
            handleSession()
        }, 1000);
        return () => clearInterval(interval);
    }, []);

  return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" >
                <Route index element={<Accueil />} />
                <Route path="NosServices" element={<PageNosServices />} />
                <Route path="NosExperts" element={<PageNosExperts />} />
                <Route path="Contact" element={<Contact/>} />
                <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route path="/service/:serviceId" element={<ExpertsByService />} />
            <Route path="/expertSelected/:ids" element={<ExpertSelectedId />} />

            <Route path="/home" element={<ExpertsServices_v3 />} />
            <Route path="/experts/:serviceId" element={<ExpertsByService />} />
            <Route path="/test2" element={<Accueil />} />
            <Route path="/meet/:id" element={<PageMeet />} />
            <Route path="/meet2" element={<JitsiMeetComponent />} />
            <Route path="/test" element={<Navbar />} />
            <Route path="/test1" element={<Header />} />
            <Route path="/Accueil" element={<Accueil />} />
            <Route path="/DetailsProspect" element={<DetailsProspect />} />
            <Route path="/congratulationPage" element={<CongratulationPage/>} />
            <Route path="/congratulationPageContact" element={<CongratulationPageContact/>} />
            <Route path="/sorryPage" element={<SorryPage/>} />
            <Route path="/refuseDemandePage" element={<RefuseDemandePage/>} />
            <Route path="/validateDemandePage" element={<ValidateDemandePage/>} />
            <Route path="/refusProspect/:id" element={<RefusProspect />} />
            <Route path="/refusExpert/:id" element={<RefusExpert />} />
            <Route path="/valideRefusProspect/:id" element={<ValideRefusProspect />} />
            <Route path="/valideRefusExpert/:id" element={<ValideRefusExpert />} />
            <Route path="/planning" element={
                <RequireAuth>
                    <RequireRole allowedRoles={['CLIENT','EXPERT']}>
                        <PageListePlanning />
                    </RequireRole>

                </RequireAuth>
            } />
            <Route path="/planningDispo" element={<PageListePlanningUpdate />} />
            <Route path="/planningUpdate" element={
                <RequireAuth>
                    <RequireRole allowedRoles={['EXPERT']}>
                        <PlanningUpdateComponent />
                    </RequireRole>
                </RequireAuth>
            } />
            <Route path="/AvisExpert/:id" element={<ExpertFeedback />} />
            <Route path="/AvisClient/:id" element={<ClientFeedback />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce} // Ensure that the attribute value is within curly braces
        />
    </BrowserRouter>
  );
}
