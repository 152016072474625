import React, {useEffect, useState} from 'react';
import Header from "./Header";
import NosServices from "./NosServices";
import NosExperts from "./NosExperts";
import Commentfonctionne from "./Commentfonctionne";
import FooterBar from "./FooterBar";
import CardCarousel from "./CardCarousel";

export default function Accueil() {

    return (
        <div>
            <Header></Header>
            <NosServices></NosServices>
            <NosExperts></NosExperts>
            <Commentfonctionne></Commentfonctionne>
            <CardCarousel></CardCarousel>
            <FooterBar></FooterBar>
        </div>
    );
}

